import React, {useEffect, useState, useRef} from 'react';
import {bindActionCreators, compose} from "redux";
import {connect, useDispatch} from "react-redux";
import * as generalActions from "../../../../redux/general/general_actions";
import * as W29F2061Actions from "../../../../redux/W2X/W29F2061/W29F2061_actions";
import * as ApprovalActions from "../../../../redux/approvals/approvals_actions";
import Api from "../../../../services/api";
import {
    Accordion, AccordionSummary, AccordionDetails, AccordionGroup, Typography, HelperText,
    DatePicker, Dropdown, TextInput, Checkbox, Modal, ModalHeader, ModalBody, ModalFooter,
    Button, Col, Row, Avatar, ButtonIcon, Attachment, NumberInput,
}                    from "diginet-core-ui/components";
import {makeStyles} from "diginet-core-ui/theme";
import Config from "../../../../config";
import {TextField} from "@material-ui/core";
import GridContainer from "../../../grid-container/grid-container";
import { AsyncRule, Column } from "devextreme-react/data-grid";
import _ from "lodash";
import moment from "moment"
import InputMask from "react-input-mask";
import GridActionBar from "../../../grid-container/grid-actionbar";
import W09F6000 from "../../../W0X/W09/W09F6000/W09F6000";
import CDN from "../../../CDN";
import HeadClick from "../../../grid-container/head-click";
import History from "../../../libs/history";

const useStyles = makeStyles((theme) => ({
    dangerColor: {
        color: theme.colors.semantic.danger
    },
}));

const W29F2061 = ({
        modeForm = "add",
        iPermission = 0,
        data = {},
        isShowModal = false,
        generalActions,
        onClose
    }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [dataForm, setDataForm] = useState({
        ShiftID: "",
        IsPreOT: false,
        ShPreOTFrom: "",
        ShPreOTTo: "",
        PreOTHour: 0,
        IsBetOT: false,
        ShBetOTFrom: "",
        ShBetOTTo: "",
        BetOTHour: 0,
        IsAftOT: false,
        ShAftOTFrom: "",
        ShAftOTTo: "",
        AftOTHour: 0,
        IsPreOTD: 0,
        IsBetOTD: 0,
        IsAftOTD: 0,

        AttendanceDate: null,
        IsOverTime: "",
        OTType: {
            OTTypeID: "",
            OTTypeName: "",
        },
        IsConfirm01: false,
        IsConfirm02: false,
        IsConfirm03: false,
        IsConfirm04: false,
        IsConfirm05: false,
        ReasonOT: "",
        Note: "",
    });
    const [dataGrid, setDataGrid] = useState([]);
    const [dataCboOTType, setDataCboOTType] = useState([]);
    const [dataCaption, setDataCaption] = useState([]);
    const [dataOldAttachments, setDataOldAttachments] = useState([]);
    const [errors, setErrors] = useState({
        AttendanceDate: "",
        PreOTHour: "",
        BetOTHour: "",
        AftOTHour: "",
        ShiftID: "",
        dataGrid: ""
    });

    const [isVisibleCboOTType, setVisibleCboOTType] = useState(0);
    const [dataCboShift, setDataCboShift] = useState([]);

    const [isLoadingForm, setLoadingForm] = useState(false);
    const [isLoadingGrid, setLoadingGrid] = useState(false);
    const [isLoadingOTType, setLoadingOTType] = useState(false);
    const [isLoadingCheckTime, setLoadingCheckTime] = useState(false);
    const [isLoadingAttachment, setLoadingAttachment] = useState(false);
    const [isExpanded, setExpanded] = useState(true);
    const [isShowModalEmployeeChosen, setShowModalEmployeeChosen] = useState(false);

    const selectedRange = useRef(null);
    const refInputMask = useRef({});
    const gridRef = useRef(null);
    const allowUpdateDataGrid = useRef(true);
    const allowHeadClick = useRef(true);
    const dataLoadCheckTime = useRef({});
    const dataCheckOtHour = useRef({});
    const selectedRowIndx = useRef(-1);
    const dataOldMaster = useRef({});
    const dataOldDetail = useRef([]);
    const dataFieldEditing = useRef("");
    const attachments = useRef([]);
    const deletedFile = useRef([]);
    const timerLoadingForm = useRef(0);
    const timerLoadingGrid = useRef(0);
    const isChecked = useRef(false);
    const isChangedTimePreOT = useRef(false);
    const isChangedTimeBetOT = useRef(false);
    const isChangedTimeAftOT = useRef(false);
    const isSubmit = useRef(false);
    const fieldRequired = ["AttendanceDate", "ShiftID",
        "PreOTHour", "BetOTHour", "AftOTHour", "dataGrid"];
    const fieldsCheckbox = ["IsPreOT", "IsBetOT", "IsAftOT"];

    const attendanceDate = _.get(dataForm, "AttendanceDate", _.get(data, "AttendanceDate", ""));
    const employeeID = _.get(data, "EmployeeID", "");
    const shiftID = _.get(dataForm, "ShiftID", _.get(data, "ShiftID", ""));
    const oTType = _.get(dataForm, "OTType", {})
    const reasonOT = _.get(dataForm, "ReasonOT", "");

    const shPreOTFrom = _.get(dataForm, "ShPreOTFrom", "");
    const shPreOTTo = _.get(dataForm, "ShPreOTTo", "");

    const shBetOTFrom = _.get(dataForm, "ShBetOTFrom", "");
    const shBetOTTo = _.get(dataForm, "ShBetOTTo", "");

    const shAftOTFrom = _.get(dataForm, "ShAftOTFrom", "");
    const shAftOTTo = _.get(dataForm, "ShAftOTTo", "");

    const setData = (obj) => {
        setDataForm({...dataForm, ...obj})
    };

    const loadShift = () => {
        const params = {
            FormID: "W29F2060",
            Mode: 0,
        };
        dispatch(W29F2061Actions.loadShift(params, (error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            setDataCboShift(data || []);
        }));
    };

    const getMode = (key) => {
        switch (key) {
            case "PreOTHour":
                return 0;
            case "BetOTHour":
                return 1;
            case "AftOTHour":
                return 2;
            default:
                return 0;
        }
    };

    const loadCheckOtHour = async (oTHour, employeeID, key) => {
        const params = {
            AttendanceDate: formatDate(attendanceDate, "YYYY-MM-DD"),
            OTHour: oTHour,
            EmployeeID: employeeID,
            ShiftID: shiftID,
            Mode: getMode(key),
        };
        return new Promise((rl, rj) => {
            dispatch(W29F2061Actions.loadCheckOtHour(params, (error, data) => {
                if (error) {
                    Config.popup.show("ERROR", error);
                    rl(false);
                }
                if(data?.Status === 1) {
                    return rl(data);
                }
                return rl(data);
            }));
        })

    };

    const loadImportOtBefore = async (PreOTFrom, PreOTTo) => {
        const params = {
            AttendanceDate: formatDate(attendanceDate, "YYYY-MM-DD"),
            PreOTFrom: PreOTFrom,
            PreOTTo: PreOTTo,
            EmployeeID: employeeID,
            ShiftID: shiftID,
            Mode: 5,
        };
        return new Promise((rl, rj) => {
            dispatch(W29F2061Actions.loadImportOtBefore(params,  (error, data) => {
                if (error) {
                    Config.popup.show("ERROR", error);
                    return rl(false);
                }
                if(data?.Status === 1) {
                    return rl(data);
                }
                return rl(data);
            }));
        })

    };

    const loadCheckOtBefore = () => {
        const params = {
            AttendanceDate: formatDate(attendanceDate, "YYYY-MM-DD"),
            ShPreOTFrom: shPreOTFrom,
            ShPreOTTo:  shPreOTTo,
            EmployeeID: employeeID,
            ShiftID: shiftID,
            Mode: 5,
        };
        setLoadingCheckTime(true);
        dispatch(W29F2061Actions.loadCheckOtBefore(params, (error, data) => {
            setLoadingCheckTime(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if(data?.Status === 1) {
                const message = _.get(data, "Message", "");
                Config.popup.show("INFO", message);
                setErrors({...errors, PreOTHour: ""});
                setData({PreOTHour: 0});
                return false;
            }
            if(allowUpdateDataGrid.current) {
                //update data grid
                const dtGrid = dataGrid.map((d) => (
                    {...d,
                        IsPreOT: 1,
                        PreOTFromPreOTTo: `${shPreOTFrom} - ${shPreOTTo}`,
                        PreOTFrom: shPreOTFrom,
                        PreOTTo: shPreOTTo,
                        PreOTHour: data?.OTHour || 0
                    }));
                setDataGrid(dtGrid);
            }
            if(allowUpdateDataGrid.current === false) allowUpdateDataGrid.current = true;
            setErrors({...errors, PreOTHour: ""});
            setData({PreOTHour: data?.OTHour || 0});
        }));
    };

    const loadImportOtBet = async (betOTFrom, betOTTo) => {
        const params = {
            AttendanceDate: formatDate(attendanceDate, "YYYY-MM-DD"),
            BetOTFrom: betOTFrom,
            BetOTTo: betOTTo,
            EmployeeID: employeeID,
            ShiftID: shiftID,
            Mode: 5,
        };
        return new Promise((rl, rj) => {
            dispatch(W29F2061Actions.loadImportOtBet(params, (error, data) => {
                if (error) {
                    Config.popup.show("ERROR", error);
                    return rl(false);
                }
                if(data?.Status === 1) {
                    return rl(data);
                }
                return rl(data);
            }));
        })

    };

    const loadCheckOtBet = () => {
        const params = {
            AttendanceDate: formatDate(attendanceDate, "YYYY-MM-DD"),
            ShBetOTFrom: shBetOTFrom,
            ShBetOTTo: shBetOTTo,
            EmployeeID: employeeID,
            ShiftID: shiftID,
            Mode: 5,
        };
        setLoadingCheckTime(true);
        dispatch(W29F2061Actions.loadCheckOtBet(params, (error, data) => {
            setLoadingCheckTime(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if(data?.Status === 1) {
                const message = _.get(data, "Message", "");
                Config.popup.show("INFO", message);
                setErrors({...errors, BetOTHour: ""});
                setData({BetOTHour: 0,});
                return false;
            }
            if(allowUpdateDataGrid.current) {
                //update data grid
                const dtGrid = dataGrid.map((d) => (
                    {...d,
                        IsBetOT: 1,
                        BetOTFromBetOTTo: `${shBetOTFrom} - ${shBetOTTo}`,
                        BetOTFrom: shBetOTFrom,
                        BetOTTo: shBetOTTo,
                        BetOTHour: data?.OTHour || 0}));
                setDataGrid(dtGrid);
            }
            if(allowUpdateDataGrid.current === false) allowUpdateDataGrid.current = true;
            setErrors({...errors, BetOTHour: ""});
            setData({BetOTHour: data?.OTHour || 0})
        }));
    };

    const loadImportOtAfter = async (aftOTFrom, aftOTTo) => {
        const params = {
            AttendanceDate: formatDate(attendanceDate, "YYYY-MM-DD"),
            AftOTFrom: aftOTFrom,
            AftOTTo: aftOTTo,
            EmployeeID: employeeID,
            ShiftID: shiftID,
            Mode: 5,
        };
        return new Promise((rl,rj) => {
            dispatch(W29F2061Actions.loadImportOtAfter(params, (error, data) => {
                if (error) {
                    Config.popup.show("ERROR", error);
                    return rl(false);
                }
                if(data?.Status === 1) {
                    return rl(data);
                }
                return rl(data);
            }));
        })

    };

    const loadCheckOtAfter = () => {
        const params = {
            AttendanceDate: formatDate(attendanceDate, "YYYY-MM-DD"),
            ShAftOTFrom: shAftOTFrom,
            ShAftOTTo: shAftOTTo,
            EmployeeID: employeeID,
            ShiftID: shiftID,
            Mode: 5,
        };
        setLoadingCheckTime(true);
        dispatch(W29F2061Actions.loadCheckOtAfter(params, (error, data) => {
            setLoadingCheckTime(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if(data?.Status === 1) {
                const message = _.get(data, "Message", "");
                Config.popup.show("INFO", message);
                setErrors({...errors, AftOTHour: ""});
                setData({AftOTHour: 0});
                return false;
            }
            //update data grid
            if(allowUpdateDataGrid.current) {
                const dtGrid = dataGrid.map((d) => (
                    {...d,
                        IsAftOT: 1,
                        AftOTFromAftOTTo: `${shAftOTFrom} - ${shAftOTTo}`,
                        AftOTFrom: shAftOTFrom,
                        AftOTTo: shAftOTTo,
                        AftOTHour: data?.OTHour || 0}));
                setDataGrid(dtGrid);
            }
            if(allowUpdateDataGrid.current === false) allowUpdateDataGrid.current = true;
            setErrors({...errors, AftOTHour: ""});
            setData({AftOTHour: data?.OTHour || 0})
        }));
    };

    useEffect(() => {
        if(isChecked.current) {
            if(dataForm.IsPreOT) {
                loadCheckOtBefore();
            } else {
                setErrors({...errors, PreOTHour: ""});
                setData({PreOTHour: 0});
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[dataForm.IsPreOT]);

    useEffect(() => {
        if(isChecked.current) {
            if(dataForm.IsBetOT) {
                loadCheckOtBet();
            } else {
                setErrors({...errors, BetOTHour: ""});
                setData({BetOTHour: 0});
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[dataForm.IsBetOT]);


    useEffect(() => {
        if(isChecked.current) {
            if(dataForm.IsAftOT) {
                loadCheckOtAfter();
            } else {
                setErrors({...errors, AftOTHour: "",});
                setData({AftOTHour: 0});
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[dataForm.IsAftOT]);

    useEffect(() => {
        if (modeForm !== "add") {
            loadAttachments();
            loadForm(0, setLoadingForm).then((resData) => {
                const getDataForm = _.get(resData, "[0]", {});
                dataOldMaster.current = getDataForm;
                if (resData) {
                    setDataForm({
                        ..._.omit(getDataForm, ["OTTypeID", "OTTypeName"]),
                        OTType: {
                            OTTypeID: _.get(getDataForm, "OTTypeID", ""),
                            OTTypeName: _.get(getDataForm, "OTTypeName", "")
                        }
                    });
                }
            });
            loadForm(1, setLoadingGrid).then((resData) => {
                if (resData) {
                    dataOldDetail.current = resData.map(d => ({...d}));
                    setDataGrid(_.map(resData, item => ({
                        ...item,
                        PreOTFromPreOTTo: `${item.PreOTFrom || "__:__"} - ${item.PreOTTo || "__:__"}`,
                        BetOTFromBetOTTo: `${item.BetOTFrom || "__:__"} - ${item.BetOTTo || "__:__"}`,
                        AftOTFromAftOTTo: `${item.AftOTFrom || "__:__"} - ${item.AftOTTo || "__:__"}`,
                        isNew: false,
                    })))
                }
            });
        }
        loadData("/w29f2011/get-ot-type").then(resData => {
            if (resData) setVisibleCboOTType(_.get(resData, "IsUseOTType", 0));
        });
        loadData("/w29f2056/load-caption").then(resData => {
            if (resData) setDataCaption(_.filter(resData, ["Disabled", true]))
        });
        loadShift();
        return () => {
            if (timerLoadingForm.current) clearTimeout(timerLoadingForm.current);
            if (timerLoadingGrid.current) clearTimeout(timerLoadingGrid.current);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let timerLoadingOTType = 0;
        const params = {
            OTFrom: "",
            OTTo:"",
            Language: Config.language || 84,
            TransID: _.get(dataForm, "TransID", _.get(data, "TransID", "")),
            Mode: 0,
        };
        setLoadingOTType(true);
        loadData("/w29f2061/load-ot-type", params).then(resData => {
            timerLoadingOTType = setTimeout(() => {
                setLoadingOTType(false)
            }, 300);
            if (resData) setDataCboOTType(resData)
        });
        return () => {
            if (timerLoadingOTType) clearTimeout(timerLoadingOTType);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const parseConfirmField = (item) => _.chain(item).pickBy((o, k) => _.startsWith(k, "IsConfirm")).mapValues((v, k, o) => o[k] = _.toNumber(v)).value();

    const formatDate = (date, format = "DD/MM/YYYY") => moment(date).isValid() ? moment(date).format(format) : null;

    const getParams = (item, isGetDataGrid = false) => {
        //isGetDataGrid = true là vô trường hợp lấy format data đem đi lưu
        if(isGetDataGrid) {
            return ({
                IsPreOT  : Number(item?.IsPreOT || 0),
                PreOTFrom: dataForm?.IsPreOT ? (item?.PreOTFrom || "") : "",
                PreOTTo  : dataForm?.IsPreOT ? (item?.PreOTTo || "") : "",
                PreOTHour: dataForm?.IsPreOT ? (Number(item?.PreOTHour || 0)) : 0,
                IsBetOT  : Number(item?.IsBetOT || 0),
                BetOTFrom: dataForm?.IsBetOT ? (item?.BetOTFrom || "") : "",
                BetOTTo  : dataForm?.IsBetOT ? (item?.BetOTTo || "") : "",
                BetOTHour: dataForm?.IsBetOT ? (Number(item?.BetOTHour || 0)) : 0,
                IsAftOT  : Number(item?.IsAftOT || 0),
                AftOTFrom: dataForm?.IsAftOT ? (item.AftOTFrom || "") : "",
                AftOTTo  : dataForm?.IsAftOT ? (item?.AftOTTo || "") : "",
                AftOTHour: dataForm?.IsAftOT ? (Number(item?.AftOTHour || 0)) : 0,
                IsPreOTD : item?.IsPreOTD || 0,
                IsBetOTD : item?.IsBetOTD || 0,
                IsAftOTD : item?.IsAftOTD || 0,
            });
        }
        return ({
            IsPreOT: Number(dataForm?.IsPreOT || 0),
            PreOTFrom: dataForm?.IsPreOT ? dataForm?.ShPreOTFrom : "",
            PreOTTo: dataForm?.IsPreOT ? dataForm?.ShPreOTTo : "",
            PreOTHour: (dataForm?.IsPreOT ? Number(dataForm?.PreOTHour || 0) : 0),
            IsBetOT: Number(dataForm?.IsBetOT || 0),
            BetOTFrom: dataForm?.IsBetOT ? dataForm?.ShBetOTFrom : "",
            BetOTTo: dataForm?.IsBetOT ? dataForm?.ShBetOTTo : "",
            BetOTHour: dataForm?.IsBetOT ? Number(dataForm?.BetOTHour || 0) : 0,
            IsAftOT: Number(dataForm?.IsAftOT || 0),
            AftOTFrom: dataForm?.IsAftOT ? dataForm?.ShAftOTFrom : "",
            AftOTTo: dataForm?.IsAftOT ? dataForm?.ShAftOTTo : "",
            AftOTHour: dataForm?.IsAftOT ? Number(dataForm?.AftOTHour || 0) : 0,
            IsPreOTD: dataForm?.IsPreOTD || 0,
            IsBetOTD: dataForm?.IsBetOTD || 0,
            IsAftOTD: dataForm?.IsAftOTD || 0,
        });
    };
    const handleDataParams = (typeData, isGetDataGrid = false) => {
        let dataParams = [];
        switch (typeData) {
            case "dataForm":
                dataParams = _.chain([dataForm])
                    .map(item => ({
                        ..._.omit(item, "OTType", "IsPreOTD", "IsBetOTD", "IsAftOTD"),
                        ...oTType,
                        ...parseConfirmField(item) ?? {},
                        AttendanceDate: formatDate(attendanceDate, "YYYY-MM-DD"),
                        Note: reasonOT,
                        PreOTFrom: item?.IsPreOT ? item.ShPreOTFrom : "",
                        PreOTTo: item?.IsPreOT ? item.ShPreOTTo : "",
                        PreOTHour: item?.IsPreOT ? item.PreOTHour : 0,
                        BetOTFrom: item?.IsBetOT ? item.ShBetOTFrom : "",
                        BetOTTo: item?.IsBetOT ? item.ShBetOTTo : "",
                        BetOTHour: item?.IsBetOT ? item.BetOTHour : 0,
                        AftOTFrom: item?.IsAftOT ? item.ShAftOTFrom : "",
                        AftOTTo: item?.IsAftOT ? item.ShAftOTTo : "",
                        AftOTHour: item?.IsAftOT ? item.AftOTHour : 0,
                        IsPreOT: Number(!!item?.IsPreOT),
                        IsBetOT: Number(!!item?.IsBetOT),
                        IsAftOT: Number(!!item?.IsAftOT),
                        OTHour: 0,
                        IsOverTime: 0,
                        OTFrom: "",
                        OTTo: "",
                    }))
                    .get("[0]", {})
                    .value();
                break;
            case "dataGrid":
                dataParams = _.map(dataGrid, item => ({
                    ..._.omit(item, "isNew", "PreOTFromPreOTTo", "BetOTFromBetOTTo", "AftOTFromAftOTTo"),
                    ...parseConfirmField(item) ?? {},
                    AttendanceDate: formatDate(_.get(item, "AttendanceDate", ""), "YYYY-MM-DD"),
                    ...getParams(item ,isGetDataGrid),
                    // OTHour: 0,
                }));
                break;
            default:
                break;
        }
        return dataParams
    };

    const checkValidFRequired = (field, value = "", isOnStore = false) => {
        let errorText = "";
        let isInValid = false;
        let valueCheck = value ? value : _.get(dataForm, field, "");
        let otherErrorField = {};
        switch (field) {
            case "AttendanceDate":
                errorText = Config.lang("Truong_nay_bat_buoc_chon");
                isInValid = _.isEmpty(valueCheck);
                otherErrorField = {ShiftID: ""}
                if (isInValid) {
                    otherErrorField = {ShiftID: Config.lang("Vui_long_chon_ngay_tang_ca")}
                }
                break;
            case "ShiftID":
                errorText = Config.lang("Truong_nay_bat_buoc_chon");
                isInValid = _.isEmpty(valueCheck);
                if (_.isEmpty(attendanceDate)) {
                    errorText = Config.lang("Vui_long_chon_tang_ca")
                }
                break;
            case "dataGrid":
                errorText = Config.lang("Vui_long_chon_nhan_vien");
                isInValid = _.size(dataGrid) > 0 ? _.every(dataGrid, ["Deleted", 1]) : true;
                break;
            default:
                break;
        }
        if (isOnStore) otherErrorField = {};
        return ({[field]: isInValid ? errorText : "", ...otherErrorField})
    };

    const handleResponse = (res) => {
        if (res?.originalError) {
            isSubmit.current = false;
        }
        const resData = _.get(res, "data", {});
        const resMessageErr = _.get(res, "message", "") || _.get(resData, "Message", "");
        if (resMessageErr) Config.popup.show("INFO", resMessageErr);
        return resData
    };

    const handleChangeForm = (key, e, keyTime1, keyTime2) => {
        let value = _.get(e, "value", _.get(e, "target.value", "")) ?? "";
        let otherDataField = {};
        const getFieldInvalid = checkValidFRequired(key, value);
        if (key === "OTType") value = _.get(_.filter(dataCboOTType, item => _.get(item, "OTTypeID", "") === value), "[0]",
                {OTTypeID: "", OTTypeName: ""});
        if(key === "ShiftID") {
            let itemCboShift = dataCboShift.find(f => f.ShiftID === value) || {};
            otherDataField = {
                ShPreOTFrom: itemCboShift?.ShPreOTFrom ?? "",
                ShPreOTTo: itemCboShift?.ShPreOTTo ?? "",
                ShBetOTFrom: itemCboShift?.ShBetOTFrom ?? "",
                ShBetOTTo: itemCboShift?.ShBetOTTo ?? "",
                ShAftOTFrom: itemCboShift?.ShAftOTFrom ?? "",
                ShAftOTTo: itemCboShift?.ShAftOTTo ?? "",
                IsPreOTD: itemCboShift?.IsPreOTD || 0,
                IsBetOTD: itemCboShift?.IsBetOTD || 0,
                IsAftOTD: itemCboShift?.IsAftOTD || 0,
            }
        }
        if(key === "PreOTFrom" || key === "PreOTTo") {
            isChangedTimePreOT.current = true;
        }
        if(key === "BetOTFrom" || key === "BetOTTo") {
            isChangedTimeBetOT.current = true;
        }
        if(key === "AftOTFrom" || key === "AftOTTo") {
            isChangedTimeAftOT.current = true;
        }

        if (_.includes(fieldRequired, key)) setErrors({...errors, ...getFieldInvalid});
        // xử lý logic những field checkbox
        if(_.includes(fieldsCheckbox, key)) {
            const dataSource = gridRef.current.instance.option("dataSource");
            // trường hợp checked checkbox
            if(value) {
                if(dataSource.length > 1) {
                    Config.popup.show("YES_NO", Config.lang("Thoi_gian_tang_ca_truoc_nay_se_duoc_gan_vao_danh_sach_nhan_vien_ban_co_dong_y_khong?"), () => {
                        // nếu chọn yes thì setstate rồi load api
                        setDataForm({...dataForm, [key]: value});
                    }, () => {
                        allowUpdateDataGrid.current = false;
                        setDataForm({...dataForm, [key]: value});
                    });
                } else {
                    setDataForm({...dataForm, [key]: value});
                }

                // else là uncheck checkbox
            } else {
                if(dataSource.length > 0) {
                    const hasValueTime = dataSource?.some(d =>
                        !(d[keyTime1] === ""  || d[keyTime1] === "__:__ - __:__") ||
                        !(d[keyTime2] === "" || d[keyTime2]  === "__:__ - __:__")
                    );
                    if(hasValueTime) {
                        let message = "";
                        let dtGrid = [];
                        switch (key) {
                            case "IsPreOT":
                                message = "Thao_tac_nay_se_xoa_het_du_lieu_tang_ca_truoc_da_nhap_truoc_do";
                                //update data grid
                                 dtGrid = dataSource.map((d) => (
                                    {...d,
                                        IsPreOT: 0,
                                        PreOTFromPreOTTo: "__:__ - __:__",
                                        PreOTFrom: "",
                                        PreOTTo: "",
                                        PreOTHour: 0
                                    }));
                                break;
                            case "IsBetOT":
                                //update data grid
                                dtGrid = dataSource.map((d) => (
                                    {...d,
                                        IsBetOT: 0,
                                        BetOTFromBetOTTo: "__:__ - __:__",
                                        BetOTFrom: "",
                                        BetOTTo: "",
                                        BetOTHour: 0
                                    }));
                                message = "Thao_tac_nay_se_xoa_het_du_lieu_tang_ca_giua_da_nhap_truoc_do";
                                break;
                            case "IsAftOT":
                                //update data grid
                                dtGrid = dataSource.map((d) => (
                                    {...d,
                                        IsAftOT: 0,
                                        AftOTFromAftOTTo: "__:__ - __:__",
                                        AftOTFrom: "",
                                        AftOTTo: "",
                                        AftOTHour: 0
                                    }));
                                message = "Thao_tac_nay_se_xoa_het_du_lieu_tang_ca_sau_da_nhap_truoc_do";
                                break;
                            default:
                                break;
                        }
                        Config.popup.show("YES_NO", Config.lang(message), () => {
                            setDataGrid(dtGrid);
                            setDataForm({...dataForm, [key]: value});
                        });
                    }
                    else {
                        setDataForm({...dataForm, [key]: value});
                    }

                } else {
                    setDataForm({...dataForm, [key]: value});
                }
            }
        } else {
            if (key) setDataForm({...dataForm, ...otherDataField, [key]: value});
        }
    };

    const loadAttachments = () => {
        const params = {
            KeyID: _.get(data, "TransID", "")
        };
        setLoadingAttachment(true);
        generalActions.getAttachmentsByTransID(params, (err, resData) => {
            setLoadingAttachment(false);
            if (err) {
                Config.popup.show("ERROR", err);
                return false;
            }
            if (data) setDataOldAttachments(resData)
        });
    };

    const loadForm = async (Mode, setLoading) => {
        const params = {
            FormID: "W29F2061",
            TransID: _.get(data, "TransID", null),
            Mode,
        };
        try {
            setLoading(true);
            const res = await Api.put("/w29f2061/load-form", params);
            switch (Mode) {
                case 0:
                    timerLoadingGrid.current = setTimeout(() => setLoading(false), 300);
                    break;
                case 1:
                    timerLoadingForm.current = setTimeout(() => setLoading(false), 300);
                    break;
                default:
                    break;
            }
            return handleResponse(res);
        } catch (e) {
            return e;
        }
    };

    const loadData = async (url = "", params = {}) => {
        if (!url) return false;
        try {
            const res = await Api[_.isEmpty(params) ? "get" : "put"](url, params);
            return handleResponse(res);
        } catch (e) {
            return e
        }
    };

    const onChosenEmployees = (dataEmployeesID) => {
        const dataParamsOfForm = handleDataParams("dataForm");
        const params = {
            FormID: "W29F2060",
            ..._.omit(dataParamsOfForm, ["ApprovalStatus", "ReasonOT", "ShiftID", "ShiftName", "TransID", "OTTypeName"]),
        };
        setLoadingGrid(true)
        loadData("/w29f2061/load-choose", params).then(resData => {
            setLoadingGrid(false);
            if (resData) {
                const dataNonOverlapping = _.uniqBy([...dataGrid, ...resData], "EmployeeID").map((item) => {
                    return ({
                        ...item,
                        PreOTFrom: insertColon(item.PreOTFrom),
                        PreOTTo: insertColon(item.PreOTTo),
                        BetOTFrom: insertColon(item.BetOTFrom),
                        BetOTTo: insertColon(item.BetOTTo),
                        AftOTFrom: insertColon(item.AftOTFrom),
                        AftOTTo: insertColon(item.AftOTTo),
                        PreOTFromPreOTTo: `${item.PreOTFrom || "__:__"} - ${item.PreOTTo || "__:__"}`,
                        BetOTFromBetOTTo: `${item.BetOTFrom || "__:__"} - ${item.BetOTTo || "__:__"}`,
                        AftOTFromAftOTTo: `${item.AftOTFrom || "__:__"} - ${item.AftOTTo || "__:__"}`,
                    })
                });
                setDataGrid(dataNonOverlapping)
            }
        });
        if (_.size(dataEmployeesID) > 0) setErrors({...errors, dataGrid: ""})
        setShowModalEmployeeChosen(false);
    };

    const onChangeAttachments = (e) => {
        attachments.current = _.get(e, "allNewAttached", []);
        if (e.removedAttached && _.size(e.removedAttached) > 0) {
            deletedFile.current = [...e.removedAttached];
            const _arrRemove = deletedFile.current.map((d) => d.AttachmentID);
            setDataOldAttachments(_.filter(dataOldAttachments, att => _.indexOf(_arrRemove, att.AttachmentID) < 0));
        }
    };

    // const onEditRow = ({data: dataRow}) => {
    //     setDataModalOvertimeEdit(dataRow);
    //     setShowModalOvertimeEdit(true);
    // }

    const onDeleteRow = ({data: dataRow}) => {
        const getEmployeeID = _.get(dataRow, "EmployeeID", "");
        const index = _.findIndex(dataGrid, ["EmployeeID", getEmployeeID]);
        const isNew = _.get(dataRow, "isNew", true);
        let data;
        if (isNew) {
            data = _.filter(dataGrid, item => item.EmployeeID !== getEmployeeID);
        } else {
            data = [
                ...dataGrid.slice(0, index),
                {
                    ...dataGrid[index],
                    Deleted: 1
                },
                ...dataGrid.slice(index + 1)
            ];
        }
        if (_.every(data, ["Deleted", 1])) {
            setErrors({...errors, dataGrid: Config.lang("Vui_long_chon_nhan_vien")})
        }
        setDataGrid(data);
    }

    // const onCloseModal = (data, isReload) => {
    //     const getEmployeeID = _.get(data, "EmployeeID", "");
    //     const getOTFrom = _.get(data, "OTFrom", "");
    //     const getOTTo = _.get(data, "OTTo", "");
    //     const getOTTypeName = _.get(_.filter(dataCboOTType, item => _.get(item, "OTTypeID", "") === _.get(data, "OTTypeID", "")), "[0]OTTypeName", "")
    //     const index = _.findIndex(dataGrid, ["EmployeeID", getEmployeeID]);
    //     setShowModalOvertimeEdit(false);
    //     if (!getEmployeeID) return false;
    //     if (isReload) {
    //         setDataGrid([
    //                 ...dataGrid.slice(0, index),
    //                 {
    //                     ...dataGrid[index],
    //                     ...data,
    //                     OTTypeName: getOTTypeName,
    //                     OTFrom: getOTFrom.substring(0, 2) + ":" + getOTFrom.substring(2, getOTFrom.length),
    //                     OTTo: getOTTo.substring(0, 2) + ":" + getOTTo.substring(2, getOTTo.length),
    //                 },
    //                 ...dataGrid.slice(index + 1)
    //             ]
    //         )
    //     }
    // }

    const getAttachments = (file) => {
        const dataFile = _.get(file, 'data.paths', []);
        const listAttachments = Config.helpers.getFileInfomations(dataFile);
        let arrAttachment = [];
        listAttachments.forEach(att => {
            arrAttachment.push({
                URL: _.get(att, "url", ""),
                FileName: _.get(att, "fileName", ""),
                FileSize: _.get(att, "fileSize", ""),
                FileExt: _.get(att, "fileExt", ""),
            });
        });
        if (dataOldAttachments && _.size(dataOldAttachments) > 0) {
            arrAttachment = dataOldAttachments.concat(arrAttachment);
        }
        return arrAttachment;
    };

    const onStore = async () => {
        if(isSubmit.current) return;
        if(!isSubmit.current) {
            isSubmit.current = true;
        }
        const dataResUploadFile = await _uploadFile(attachments.current, true);
        const arrAttachments = getAttachments(dataResUploadFile);
        const dataParamsOfForm = handleDataParams("dataForm");
        const dataParamsOfGrid = handleDataParams("dataGrid", true);
        let params = {
            ...dataParamsOfForm,
            Language: Config.language || "84",
            data: JSON.stringify(dataParamsOfGrid),
            arrAttachment: JSON.stringify(arrAttachments)
        };
        const dataError = _.chain(fieldRequired)
            .map(item => checkValidFRequired(item, "", true))
            .reduce((acc, item) => {
                acc[_.keys(item)] = item[_.keys(item)];
                return acc;
            }, {})
            .value();
        setErrors(dataError);
        if (_.some(_.values(dataError), text => !_.isEmpty(text))) {
            isSubmit.current = false;
            return false;
        }
        if (_.chain(dataParamsOfGrid).filter(['Deleted', 0]).uniqBy("ShiftID").size().value() > 1) {
            Config.popup.show("INFO", Config.lang("Ton_tai_nhieu_hon_1_ca_lam_viec_trong_cung_mot_ngay_lam_viec") + ". " + Config.lang("Khong_duoc_phep_luu"))
            isSubmit.current = false;
            return false;
        }
        if (_.some(dataParamsOfGrid, item => _.get(item, "ShiftID", "") === "")) {
            Config.popup.show("INFO", Config.lang("Ton_tai_nhan_vien_chua_co_ca_lam_viec") + ". " + Config.lang("Khong_duoc_phep_luu"))
            isSubmit.current = false;
            return false;
        }
        setLoadingForm(true);
        const res = await Api[modeForm === "add" ? "post" : "put"](`/w29f2061/${modeForm}`, params);
        setLoadingForm(false);
        isSubmit.current = false;
        const resData = handleResponse(res);
        if (_.get(resData, "Status", 1) === 0) {
            _removeCDN();
            await saveHistory(params, modeForm, resData.TransID);
            Config.notify.show("success", Config.lang("Luu_thanh_cong"), 2000);
            onClose(true);
            return true;
        } else {
            if(_.get(resData, "MsgAsk", "") === 0) {
                Config.popup.show("INFO", _.get(resData, "Message", ""));
            }
            if(_.get(resData, "MsgAsk", "") === 1) {
                Config.popup.show('YES_NO', _.get(resData, "Message", "") , () => {
                    params = {...params,code: _.get(resData, "code", "") };
                    _saveData(params, modeForm);
                });
            }
        }
    };
    const getIsConfirm = (data) => {
        return `${(data.IsConfirm01 ? Config.lang("Co") : Config.lang("Khong")) + " - " +
        (data.IsConfirm02 ? Config.lang("Co") : Config.lang("Khong")) + " - " +
        (data.IsConfirm02 ? Config.lang("Co") : Config.lang("Khong"))  + " - " +
        (data.IsConfirm03 ? Config.lang("Co") : Config.lang("Khong")) + " - " +
        (data.IsConfirm04 ? Config.lang("Co") : Config.lang("Khong")) + " - " +
        (data.IsConfirm05 ? Config.lang("Co") : Config.lang("Khong"))}`;
    };
    const saveHistory = async (data, mode, _TransID) => {
        if(!data) return false;
        const TransID = mode === "add" ? _TransID :  _.get(data, "TransID", "");
        const _mode = mode === "add" ? 0 : 1;
        let dataSource = gridRef.current.instance.option('dataSource');
        const shift = dataCboShift.find(d => d.ShiftID === dataForm.ShiftID);
        if (TransID) {

            //data master
            const _data = {
                AttendanceDate: data.AttendanceDate,
                Shift: `${shift.ShiftID} - ${shift.ShiftName}`,
                PreOT: `${data.PreOTFrom} - ${data.PreOTTo} | ${data.PreOTTo}`,
                BetOT: `${data.BetOTFrom} - ${data.BetOTTo} | ${data.BetOTHour}`,
                AftOT: `${data.AftOTFrom} - ${data.AftOTTo} | ${data.AftOTHour}`,
                IsConfirm: getIsConfirm(data),
                ReasonOT: data.ReasonOT,
                OTType: `${data.OTTypeID} - ${data.OTTypeName}`,
            };
            //name master
            const captions = {
                AttendanceDate: "Ngay_cong",
                Shift: "Ca",
                PreOT: "Tang_ca_truoc",
                BetOT: "Tang_ca_giua",
                AftOT: "Tang_ca_sau",
                IsConfirm: "Xac_nhan",
                ReasonOT: "Ly_do_tang_ca",
                OTType: "Loai_tach_tang_ca",
            };
            // name detail grid
            let detailCaptions = {
                Employee: "Nhan_vien",
                AttendanceDate: "Ngay_cong",
                Shift: "Ca",
                PreOT: "tang_ca_truoc",
                BetOT: "tang_ca_giua",
                AftOT: "tang_ca_sau",
                IsConfirm: "Xac_nhan",
                OTType: "Loai_tach_tang_ca",
                Note: "Ly_do",
            };
            // data form master
            const _dataOld = {
                AttendanceDate: formatDate(dataOldMaster.current.AttendanceDate, "YYYY-MM-DD"),
                Shift: `${shift.ShiftID} - ${shift.ShiftName}`,
                PreOT: `${dataOldMaster.current.PreOTFrom} - ${dataOldMaster.current.PreOTTo} | ${dataOldMaster.current.PreOTTo}`,
                BetOT: `${dataOldMaster.current.BetOTFrom} - ${dataOldMaster.current.BetOTTo} | ${dataOldMaster.current.BetOTHour}`,
                AftOT: `${dataOldMaster.current.AftOTFrom} - ${dataOldMaster.current.AftOTTo} | ${dataOldMaster.current.AftOTHour}`,
                IsConfirm: getIsConfirm(dataOldMaster.current),
                ReasonOT: dataOldMaster.current.ReasonOT,
                OTType: `${dataOldMaster.current.OTTypeID} - ${dataOldMaster.current.OTTypeName}`,
            };

            const options = {
                data: _data,
                captions: captions,
                dataCompare: _dataOld,
                action: _mode,
                ModuleID: "D29",
                TransID: TransID,
                TransactionID: "W29F2060",
                TransactionName: Config.lang("Dang_ky_tang_ca_ho"),
            };

            const _dataOldDetail = dataOldDetail.current.map(i=>{
                return {
                    EmployeeID: i.EmployeeID,
                    Employee : `${i.EmployeeID} - ${i.EmployeeName}`,
                    AttendanceDate: i.AttendanceDate,
                    Shift : `${shift.ShiftID} - ${shift.ShiftName}`,
                    PreOT : `${i.PreOTFrom} - ${i.PreOTTo} | ${i.PreOTHour}`,
                    BetOT : `${i.BetOTFrom} - ${i.BetOTTo} | ${i.BetOTHour}`,
                    AftOT : `${i.AftOTFrom} - ${i.AftOTTo} | ${i.AftOTHour}`,
                    OTType : `${i.OTTypeID} - ${i.OTTypeName}`,
                    Note: i.Note,
                    IsConfirm : getIsConfirm(i),
                }
            });

            const dataNew = JSON.parse(JSON.stringify(dataSource)).map(i=>{
                return {
                    EmployeeID: i.EmployeeID,
                    Employee : `${i.EmployeeID} - ${i.EmployeeName}`,
                    AttendanceDate: i.AttendanceDate,
                    Shift : `${shift.ShiftID} - ${shift.ShiftName}`,
                    PreOT : `${i.PreOTFrom} - ${i.PreOTTo} | ${i.PreOTHour}`,
                    BetOT : `${i.BetOTFrom} - ${i.BetOTTo} | ${i.BetOTHour}`,
                    AftOT : `${i.AftOTFrom} - ${i.AftOTTo} | ${i.AftOTHour}`,
                    OTType : `${i.OTTypeID} - ${i.OTTypeName}`,
                    Note: i.Note,
                    IsConfirm : getIsConfirm(i)
                }
            });

            const history = new History(options); //Init history
            history.createDetailHistory("Nhan_vien", dataNew, _dataOldDetail, detailCaptions, "EmployeeID", null, options); //Create detail..
            await history.save();
        }
    };

    const _uploadFile = (files, isAsync, cb) => {
        if (isAsync) {
            return CDN.uploadFileSync(files);
        } else {
            return CDN.uploadFile(files, null, cb);
        }
    };

    const _removeCDN = () => {
        if (deletedFile.current && deletedFile.current.length > 0) {
            deletedFile.current.forEach((e) => {
                const path = e.URL.split("=");
                if (path && path.length > 1) {
                    const params = {
                        path: path[1]
                    };
                    CDN.removeFile(params);
                }
            });
            deletedFile.current = [];
        }
    };

    const _saveData = async (params, modeForm) => {
        setLoadingForm(true);
        const res = await Api.put(`/w29f2061/confirm-${modeForm}`, params);
        setLoadingForm(false);
        const resData = handleResponse(res);
        if (_.get(resData, "Status", 1) === 1) {
            Config.popup.show("INFO", _.get(resData, "Message", ""));
            isSubmit.current = false;
            return true;
        }
        if (_.get(resData, "Status", 1) === 0) {
            _removeCDN();
            await saveHistory(params, modeForm, resData.TransID);
            Config.notify.show("success", Config.lang("Luu_thanh_cong"), 2000);
            onClose(true);
            isSubmit.current = false;
            return true;
        }
    };

    const loadUpdateGrid = (Choice = 0) => {
        const dataParamsOfForm = handleDataParams("dataForm");
        const dataParamsOfGrid = handleDataParams("dataGrid");
        const params = {
            ...dataParamsOfForm,
            Choice,
            data: JSON.stringify(dataParamsOfGrid),
        }
        setLoadingGrid(true);
        loadData("/w29f2055/update", params).then(res => {
            if (res) {
                if(Array.isArray(res) && res.length === 0) return setDataGrid([]);
                const dataGridUpdated = _.map(dataGrid, item => _.extend(item, _.find(res, (d) => {
                    if(d.EmployeeID === item.EmployeeID) {
                        item.PreOTFromPreOTTo = `${d.PreOTFrom || "__:__"} - ${d.PreOTTo || "__:__"}`;
                        item.BetOTFromBetOTTo = `${d.BetOTFrom || "__:__"} - ${d.BetOTTo || "__:__"}`;
                        item.AftOTFromAftOTTo = `${d.AftOTFrom || "__:__"} - ${d.AftOTTo || "__:__"}`;
                        return true;
                    }
                    return false;
                })));
                setDataGrid(dataGridUpdated);
            }
        })
        if (timerLoadingGrid.current) clearTimeout(timerLoadingGrid.current);
        timerLoadingGrid.current = setTimeout(() => {
            setLoadingGrid(false);
        }, 300)
    }

    const onUpdateGrid = () => {
        const isAddEmp = _.every(dataGrid, item => !!_.get(item, "AddEmp", 0));
        Config.popup.show({
                ...(!isAddEmp ? {
                    yesText: Config.lang("Tat_ca"),
                    noText: Config.lang("Moi_bo_sung"),
                } : {}),
                type: "YESNO"
            },
            isAddEmp ? Config.lang("Ban_co_muon_cap_nhat_thong_tin_tang_ca_nhan_vien") : Config.lang("Ban_co_muon_cap_thong_tin_tang_ca_nhan_vien"),
            () => loadUpdateGrid(),
            () => !isAddEmp && loadUpdateGrid(1)
        );
    };

    const renderAvatar = ({data}) => {
        const user = Config.getUser({EmployeeID: data?.EmployeeID || ""});
        const info = {
            [Config.lang('Nhan_vien')]: `${user?.EmployeeID} - ${user?.EmployeeName}`,
            [Config.lang('Phong_ban')]: user?.DepartmentName || "",
            [Config.lang('Du_an')]: user?.ProjectName || "",
            [Config.lang('Chuc_vu')]: user?.DutyName || "",
            [Config.lang('Ngay_vao_lam')]: Config.convertDate(user?.DateJoined, '', 'L'),
        };
        return (<div className={"display_row align-center"}>
            <Avatar
                readOnly
                width={24}
                height={24}
                data={info}
                hoverAble
                src={Config.getUserPicture(user?.UserPictureURL)}
            />
        </div>)
    }

    const renderAction = (e) => {
        return <GridActionBar>
            <div className="">
                {/*<IconButton*/}
                {/*    disabled={modeForm === "view"}*/}
                {/*    aria-label="view"*/}
                {/*    style={{padding: 0, width: 30, height: 30, marginRight: 4}}*/}
                {/*    onClick={() => onEditRow(e)}*/}
                {/*>*/}
                {/*    <Icons name={"edit"}/>*/}
                {/*</IconButton>*/}
                <ButtonIcon
                    viewType={"text"}
                    circular
                    name={"delete"}
                    disabled={modeForm === "view"}
                    className={"mgr1x"}
                    size={"small"}
                    onClick={() => onDeleteRow(e)}
                />
            </div>
        </GridActionBar>
    }

    const checkFieldError = (group = "All") => {
        let objErrors = errors;
        switch (group) {
            case "Info":
                objErrors = _.omit(errors, "dataGrid");
                break;
            case "Grid":
                objErrors = _.pick(errors, "dataGrid");
                break;
            default:
                break;
        }
        return _.some(_.values(objErrors), text => !_.isEmpty(text));
    }

    const isErrors = checkFieldError();
    const isErrorInfo = checkFieldError("Info");
    const isErrorDataGrid = checkFieldError("Grid");
    const maskRules = {
        'A': /[0-9]/,
        'B': /[0-9]/,
        'C': /[0-5]/,
        'D': /[0-9]/,
    };

    const renderEmployee = (e) => {
        const {data} = e?.row || {};
        return (
            <div className={"display_row align-center"}>
                {renderAvatar(e)}
                <Typography className={"mgl2x"}>{`${data?.EmployeeID} - ${data?.EmployeeName}`}</Typography>
            </div>
        )
    };

    const renderHeaderColumns = (e) => {
        if (!e) return false;
        return(
            <HeadClick selectedRange={selectedRange.current}
                       dataHeaderCell={e}
                       allowClick={() => {
                           return allowHeadClick.current;
                       }}
                       isAllData={true}
                       onUpdate={(data,column)=>{
                           data.Updated = 1;
                           let rowData = {};
                           if(gridRef.current) {
                               const rowKey = gridRef.current.instance.getKeyByRowIndex(selectedRowIndx.current);
                               rowData = dataGrid.find(d => d.EmployeeID === rowKey);
                           }
                           if(column.dataField === "PreOTFromPreOTTo" &&
                               data["PreOTFromPreOTTo"] && !data["PreOTFromPreOTTo"].includes("__")){
                               data["PreOTFrom"] = data["PreOTFromPreOTTo"].split(" - ")[0];
                               data["PreOTTo"] = data["PreOTFromPreOTTo"].split(" - ")[1];
                               data["PreOTHour"] = rowData?.PreOTHour || 0;
                           }
                           if(column.dataField === "PreOTHour"){
                               data["PreOTFrom"] = rowData.PreOTFromPreOTTo.split(" - ")[0];
                               data["PreOTTo"] = rowData.PreOTFromPreOTTo.split(" - ")[1];
                               data["PreOTFromPreOTTo"] = rowData?.PreOTFromPreOTTo || "__:__ - __:__";
                           }
                           if(column.dataField === "BetOTFromBetOTTo" &&
                               data["BetOTFromBetOTTo"] && !data["BetOTFromBetOTTo"].includes("__")){
                               data["BetOTFrom"] = data["BetOTFromBetOTTo"].split(" - ")[0];
                               data["BetOTTo"] = data["BetOTFromBetOTTo"].split(" - ")[1];
                               data["BetOTHour"] = rowData?.BetOTHour || 0;
                           }
                           if(column.dataField === "BetOTHour"){
                               data["BetOTFrom"] = rowData.BetOTFromBetOTTo.split(" - ")[0];
                               data["BetOTTo"] = rowData.BetOTFromBetOTTo.split(" - ")[1];
                               data["BetOTFromBetOTTo"] = rowData?.BetOTFromBetOTTo || "__:__ - __:__";
                           }
                           if(column.dataField === "AftOTFromAftOTTo" &&
                               data["AftOTFromAftOTTo"] && !data["AftOTFromAftOTTo"].includes("__")){
                               data["AftOTFrom"] = data["AftOTFromAftOTTo"].split(" - ")[0];
                               data["AftOTTo"] = data["AftOTFromAftOTTo"].split(" - ")[1];
                               data["AftOTHour"] = rowData?.AftOTHour || 0;
                           }
                           if(column.dataField === "AftOTHour"){
                               data["BetOTFrom"] = rowData.AftOTFromAftOTTo.split(" - ")[0];
                               data["BetOTTo"] = rowData.AftOTFromAftOTTo.split(" - ")[1];
                               data["AftOTFromAftOTTo"] = rowData?.AftOTFromAftOTTo || "__:__ - __:__";
                           }
                       }}
            />
        )
    };

    const renderInputTime = (e, key1, key2) => {
        if(!e) return null;
        const {data} = e?.row || {};
        const dataField = `${key1}${key2}`;
        return  <InputMask
            mask={'AB:CD - AB:CD'}
            alwaysShowMask={true}
            formatChars={maskRules}
            defaultValue={`${data[dataField]}`}
            onBlur={(evt) => {
                const values = evt.target.value.split(' - ');
                let from = values[0];
                let to = values[1];
                const currentValue = e.component.cellValue(e.rowIndex, dataField);
                if (currentValue === `${from} - ${to}`) return false;
                allowHeadClick.current = false;
                // khi setValue thì sẽ chạy hàm asyncRule
                e.setValue(`${from} - ${to}`);
            }}
            onChange={(evt) => {
                const { value } = evt.target;
                let from = value.split(" - ")[0];
                if (from.split("_").length <= 3) {
                    from = from.replace(/_/g, "0");
                }

                let to = value.split(" - ")[1];
                if (to.split("_").length <= 3) {
                    to = to.replace(/_/g, "0");
                }
                if (from.split("_").length === 4 && from[0] !== "_") {
                    const firstLetterFrom = Number(from[0]);
                    from = (firstLetterFrom >= 3 ? `0${firstLetterFrom}` : `${firstLetterFrom}0`) + `:00`;
                } else if (to.split("_").length === 4 && to[0] !== "_") {
                    const firstLetterTo = Number(to[0]);
                    to = (firstLetterTo >= 3 ? `0${firstLetterTo}` : `${firstLetterTo}0`) + `:00`;
                }
                //set value input
                refInputMask.current[e.rowIndex].value = `${from} - ${to}`;
            }}
        >
            {(props) => <TextField {...props} ref={ref => refInputMask.current[e.rowIndex] = ref} variant={"standard"}/>}
        </InputMask>
    };

    const renderNumberInput = (e, nameInput) => {
        return <NumberInput
            nonStyle
            disabledNegative
            decimalDigit={4}
            decimalSymbol={"."}
            thousandSeparator={','}
            value={e?.value}
            onBlur={evt => {
                const currentValue = e.component.cellValue(e.rowIndex, nameInput);
                // kiểm tra nếu giá trị hiện tại của cell đó = value input thì return
                if (String(currentValue) === String(evt.target.value)) return false;
                allowHeadClick.current = false;
                //khi chạy e.setValue thì asyncRule sẽ chạy
                e.setValue(evt.target.value);
            }}
            onChange={() => {}}
        />
    };

    const cellRenderNumberInput = (e) => {
        if(!e) return null;
        return <Typography type={"h3"} color={"info"}>{e.value}</Typography>
    };

    const asyncRuleCellTime = async (e, key, keyTime1, keyTime2, loadImportOt) => {
        return new Promise(async (resolve, rj) => {
            const shift = dataCboShift.find(d => d.ShiftID === dataForm.ShiftID);
            // kiểm tra trùng tên Field đang edit và thời gian khác thời gian của ShiftID đã chọn
            if(e.column.dataField === key &&  dataFieldEditing.current === key &&
                e.value !== `${shift[`Sh${keyTime1}`]} - ${shift[`Sh${keyTime2}`]}`) {
                const valueField = e.value;
                const values = valueField.split(' - ');
                let from = values[0];
                let to = values[1];

                //  kiểm tra input lớn hơn 24h
                if(Number(from.split(":")[0]) > 24 || Number(to.split(":")[0]) > 24) {
                    e.rule.message = Config.lang("Thoi_gian_khong_hop_le");
                    return resolve(false);
                }

                // kiểm tra input time rỗng
                if(valueField === "__:__ - __:__") {
                    return resolve(true);
                }
                // kiểm tra input nhập giờ chưa đủ
                if(((from || to) && (from !== "__:__" || to !== "__:__") && (from.includes("_") || to.includes("_")))) {
                    return resolve(false);
                }
                // kiểm tra input nhập giờ đủ
                if(valueField !== "" || valueField !== "__:__ - __:__" || !valueField.includes("_")) {
                    const result = await loadImportOt(from, to, e);
                    if(result.Status === 1) {
                        // custom lỗi theo trường Message
                        e.rule.message = `${result?.Message ?? ""}`;
                        return resolve(false);
                    } else {
                        dataLoadCheckTime.current = {...result,dataField: key};
                        return resolve(true);
                    }
                }
                return resolve(true);
            } else {
                return resolve(true);
            }
        });
    };

    const asyncRuleCellHour = async (e, key) => {
        return new Promise(async (resolve, rj) => {
            if(e.column.dataField === key && dataFieldEditing.current === key &&
                e.value !== "" && e.value !== dataForm[key]) {
                const {value} = e;
                const result = await loadCheckOtHour(value, e.data.EmployeeID, key);
                if(result?.Status === 1) {
                    e.rule.message = `${result?.Message ?? ""}`;
                    return resolve(false);
                } else {
                    dataCheckOtHour.current = {...result,dataField: key};
                    return resolve(true);
                }
            } else {
                return resolve(true);
            }
        });
    };

    const insertColon = time => {
        if (!time) return "";
        if (time.includes(":")) return time;
        return `${time.slice(0, 2)}:${time.slice(2)}`;
    };

    const onRowUpdating = e => {
        const { newData, oldData } = e || {};
        const isPreOTFromPreOTTo = newData.hasOwnProperty("PreOTFromPreOTTo") &&
            dataLoadCheckTime.current?.dataField === "PreOTFromPreOTTo" &&
            Object.keys(dataLoadCheckTime.current).length > 0;

        const isBetOTFromBetOTTo = newData.hasOwnProperty("BetOTFromBetOTTo") &&
            dataLoadCheckTime.current?.dataField === "BetOTFromBetOTTo" &&
            Object.keys(dataLoadCheckTime.current).length > 0;

        const isAftOTFromAftOTTo = newData.hasOwnProperty("AftOTFromAftOTTo") &&
            dataLoadCheckTime.current?.dataField === "AftOTFromAftOTTo" &&
            Object.keys(dataLoadCheckTime.current).length > 0;

        const isPreOTHour = newData.hasOwnProperty("PreOTHour") &&
            dataCheckOtHour.current?.dataField === "PreOTHour" &&
            Object.keys(dataCheckOtHour.current).length > 0;

        const isBetOTHour = newData.hasOwnProperty("BetOTHour") &&
            dataCheckOtHour.current?.dataField === "BetOTHour" &&
            Object.keys(dataCheckOtHour.current).length > 0;

        const isAftOTHour = newData.hasOwnProperty("AftOTHour") &&
            dataCheckOtHour.current?.dataField === "AftOTHour" &&
            Object.keys(dataCheckOtHour.current).length > 0;
        if(isPreOTFromPreOTTo) {
            updateTime(
                newData,
                oldData,
                "PreOTFrom",
                "PreOTTo",
                "PreOTHour")
        }else if(isBetOTFromBetOTTo) {
            updateTime(
                newData,
                oldData,
                "BetOTFrom",
                "BetOTTo",
                "BetOTHour")
        }else if(isAftOTFromAftOTTo) {
            updateTime(
                newData,
                oldData,
                "AftOTFrom",
                "AftOTTo",
                "AftOTHour")
        }else if(isPreOTHour) {
            updateHour(newData,"PreOTFrom", "PreOTTo", "PreOTHour")
        } else if(isBetOTHour) {
            updateHour(newData,"BetOTFrom", "BetOTTo", "BetOTHour")
        } else if(isAftOTHour) {
            updateHour(newData,"AftOTFrom", "AftOTTo", "AftOTHour")
        }
    };

    const updateTime = (newData, oldData, keyTime1, keyTime2, keyHour) => {
        const shift = dataCboShift.find(d => d.ShiftID === dataForm.ShiftID);
        let otHour = dataLoadCheckTime.current?.OTHour ?? oldData[keyHour];
        // kiểm tra đk nếu time = time theo combo ca thì xét lại otHour
        if(`${shift[`Sh${keyTime1}`]} - ${shift[`Sh${keyTime2}`]}` === newData[`${keyTime1}${keyTime2}`]) {
            otHour = dataForm[keyHour];
        }
        const oTFromOTTo = newData[`${keyTime1}${keyTime2}`].split(" - ");
        newData[keyTime1] = oTFromOTTo[0];
        newData[keyTime2] = oTFromOTTo[1];
        newData[keyHour] = otHour;
    };

    const updateHour = (newData, keyTime1, keyTime2, keyHour) => {
        const shift = dataCboShift.find(d => d.ShiftID === dataForm.ShiftID);
        let oTFromOTTo = `${insertColon(dataCheckOtHour.current.OTFrom)} - ${insertColon(dataCheckOtHour.current.OTTo)}`;
        let oTFrom = insertColon(dataCheckOtHour.current.OTFrom);
        let oTTo = insertColon(dataCheckOtHour.current.OTTo);
        // kiểm tra đk nếu time = time PreOTHour đã cho thì gán giá trị PreOTFromPreOTTo cho cột PreOTFromPreOTTo
        // parse string vì newData.PreOTHour trong lưới trả ra là string
        if(String(dataForm[keyHour]) === String(newData[keyHour])) {
            oTFromOTTo =`${shift[`Sh${keyTime1}`]} - ${shift[`Sh${keyTime2}`]}`;
        }
        newData[[`${keyTime1}${keyTime2}`]] = oTFromOTTo;
        newData[keyTime1] = oTFrom;
        newData[keyTime2] = oTTo;
    };

    return <>
        {isShowModalEmployeeChosen &&
        <W09F6000
            loadParams={{ShiftID: shiftID, AttendanceDate: attendanceDate}}
            open={isShowModalEmployeeChosen} dataDefaults={[]} FormID={"W29F2060"}
            onChosen={onChosenEmployees}
            onClose={() => setShowModalEmployeeChosen(false)}/>
        }
        <Modal pressESCToClose width={"100%"} open={isShowModal} onClose={() => onClose()}>
            <ModalHeader title={Config.lang("Dang_ky_tang_ca_ho")}/>
            <ModalBody style={{height: "100vh"}}>
                <AccordionGroup collapseOther={false}>
                    <Accordion
                        expand
                        onCollapse={() => setExpanded(false)}
                        onExpand={() => setExpanded(true)}
                    >
                        <AccordionSummary   >
                            <div>
                                <Typography color={isErrorInfo ? "danger" : ""} type={"h3"}>
                                    {Config.lang("Thong_tin")}
                                    <span className={classes.dangerColor}>*</span>
                                </Typography>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Row>
                                <Col xs={12} sm={6}>
                                    <DatePicker
                                        error={errors["AttendanceDate"]}
                                        label={Config.lang("Ngay_tang_ca")}
                                        actionIconAt={'start'}
                                        placeholder={'DD/MM/YYYY'}
                                        displayFormat={'DD/MM/YYYY'}
                                        value={attendanceDate}
                                        viewType={'underlined'}
                                        onChange={(e) => handleChangeForm("AttendanceDate", e)}
                                        style={{margin: 0}}
                                        clearAble
                                        readOnly={modeForm !== "add"}
                                        disabled={isLoadingForm || isLoadingCheckTime}
                                        required={true}
                                    />
                                </Col>
                                <Col xs={12} sm={6}>
                                    <Dropdown
                                        error={errors["ShiftID"]}
                                        readOnly={modeForm !== "add"}
                                        disabled={_.isEmpty(attendanceDate) || isLoadingForm || isLoadingCheckTime}
                                        required={true}
                                        allowSearch
                                        dataSource={dataCboShift}
                                        valueExpr={"ShiftID"}
                                        displayExpr={"ShiftName"}
                                        label={Config.lang("Ca")}
                                        onChange={e => {
                                            const value = _.get(e, "value", "");
                                            if (_.isEmpty(value)) {
                                                setDataForm({
                                                    ...dataForm,
                                                    ShiftID: "",
                                                    ShPreOTFrom: "",
                                                    ShPreOTTo: "",
                                                    PreOTHour: 0,
                                                    ShBetOTFrom: "",
                                                    ShBetOTTo: "",
                                                    BetOTHour: 0,
                                                    ShAftOTFrom: "",
                                                    ShAftOTTo: "",
                                                    AftOTHour: 0,
                                                    IsPreOTD: 0,
                                                    IsBetOTD: 0,
                                                    IsAftOTD: 0,
                                                    IsPreOT: false,
                                                    IsBetOT: false,
                                                    IsAftOT: false,
                                                })
                                            } else {
                                                handleChangeForm("ShiftID", e);
                                                setDataGrid([]);
                                            }
                                        }}
                                        value={shiftID}
                                        clearAble
                                        viewType={'underlined'}
                                    />
                                </Col>
                                <Col xs={12} md={6} sm={12}>
                                    {isVisibleCboOTType > 0 &&
                                    <Dropdown
                                        loading={isLoadingOTType}
                                        disabled={isLoadingForm || isLoadingCheckTime}
                                        allowSearch
                                        readOnly={modeForm === "view"}
                                        dataSource={dataCboOTType}
                                        displayExpr={"OTTypeName"}
                                        valueExpr={"OTTypeID"}
                                        value={oTType.OTTypeID}
                                        label={Config.lang("Loai_tach_ca")}
                                        onChange={(e) => handleChangeForm("OTType", e)}
                                        clearAble
                                        viewType={'underlined'}
                                    />
                                    }
                                </Col>
                                <Col md={6} sm={12} xs={12}>
                                    <Row>
                                        <Col xs={12} sm={4}>
                                            <Checkbox checked={dataForm.IsPreOT}
                                                      onChange={e => {
                                                          if(!isChecked.current) {
                                                              isChecked.current = true;
                                                          }
                                                          handleChangeForm("IsPreOT", e, "PreOTFrom","PreOTTo")
                                                      }}
                                                      labelProps={{type: "p1"}}
                                                      className={"mgt2x"}
                                                      label={Config.lang("Tang_ca_truoc")}
                                                      disabled={_.isEmpty(attendanceDate) || isLoadingForm ||
                                                      modeForm === "view" || dataForm?.IsPreOTD === 0
                                                      || isLoadingCheckTime}
                                            />
                                            <Typography className={"mgl6x"} type={"p3"}>
                                                {`${shPreOTFrom ?
                                                    shPreOTFrom + " - ": "" }${shPreOTTo}`}
                                            </Typography>
                                        </Col>
                                        <Col xs={12} sm={2}>
                                            <Typography className={"mgt4x"} color={"info"}>
                                                {`${Math.round((dataForm?.PreOTHour || 0) * 100) / 100} h` || ""}
                                            </Typography>
                                            <HelperText
                                                hoverTooltip lineClamp={1}>
                                                {errors["PreOTHour"] ?? errors["PreOTHour"]}</HelperText>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={6} sm={12} xs={12}>
                                    <div className={"display_row flex-wrap"}>
                                        {_.map(dataCaption, (item, i) => {
                                            const refID = _.get(item, "RefID", "");
                                            return <Checkbox
                                                    key={i}
                                                    className={"pdr4x"}
                                                    checked={!!dataForm[refID]}
                                                    onChange={(e) => handleChangeForm(refID, e)}
                                                    color="primary"
                                                    label={_.get(item, "RefCaption", "")}
                                                    disabled={isLoadingCheckTime}
                                                />
                                        })}
                                    </div>
                                </Col>

                                <Col md={6} sm={12} xs={12}>
                                    <Row>
                                        <Col xs={12} sm={4}>
                                            <Checkbox checked={dataForm.IsBetOT}
                                                      onChange={(e) => {
                                                          if(!isChecked.current) {
                                                              isChecked.current = true
                                                          }
                                                          handleChangeForm("IsBetOT", e, "BetOTFrom","BetOTTo")
                                                      }}
                                                      disabled={_.isEmpty(attendanceDate) || isLoadingForm
                                                      || modeForm === "view" || !dataForm?.IsBetOTD
                                                      || isLoadingCheckTime}
                                                      label={Config.lang("Tang_ca_giua")}/>
                                            <Typography className={"mgl6x"} type={"p3"}>
                                                {`${shBetOTFrom ? shBetOTFrom + " - " : ""} ${shBetOTTo}`}
                                            </Typography>
                                        </Col>
                                        <Col xs={12} sm={2}>
                                            <Typography className={"mgt4x"} color={"info"}>
                                                {`${Math.round((dataForm?.BetOTHour || 0) * 100) / 100} h` || ""}
                                            </Typography>
                                            <HelperText
                                                hoverTooltip lineClamp={1}>
                                                {errors["BetOTHour"] ?? errors["BetOTHour"]}</HelperText>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={6} sm={12} xs={12}>
                                    <TextInput
                                        disabled={isLoadingForm || isLoadingCheckTime}
                                        value={reasonOT}
                                        readOnly={modeForm === "view"}
                                        label={Config.lang("Ly_do_tang_ca")}
                                        placeholder={Config.lang("Nhap")}
                                        onChange={e => handleChangeForm("ReasonOT", e)}
                                    />
                                </Col>

                                <Col md={6} sm={12} xs={12}>
                                    <Row>
                                        <Col xs={12} sm={4}>
                                            <Checkbox checked={dataForm.IsAftOT}
                                                      onChange={(e) => {
                                                      if(!isChecked.current) {
                                                          isChecked.current = true
                                                      }
                                                      handleChangeForm("IsAftOT", e, "AftOTFrom", "AftOTTo")
                                                  }}
                                                  disabled={_.isEmpty(attendanceDate) || isLoadingForm
                                                  || modeForm === "view" || !dataForm?.IsAftOTD
                                                  || isLoadingCheckTime}
                                                  label={Config.lang("Tang_ca_sau")}/>
                                            <Typography className={"mgl6x"} type={"p3"}>
                                                {`${shAftOTFrom ? shAftOTFrom + " - " : ""}${shAftOTTo}`}
                                            </Typography>
                                        </Col>
                                        <Col xs={12} sm={2}>
                                            <Typography className={"mgt4x"} color={"info"}>
                                                {`${Math.round((dataForm?.AftOTHour || 0) * 100) / 100} h` || ""}
                                            </Typography>
                                            <HelperText
                                                hoverTooltip lineClamp={1}>
                                                {errors["AftOTHour"] ?? errors["AftOTHour"]}</HelperText>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expand>
                        <AccordionSummary>
                            <Typography
                                type={"h3"}
                                color={`${isErrorDataGrid ? "danger" : ""} `}>
                                {Config.lang("Danh_sach_nhan_vien")} <span className={classes.dangerColor}>*</span>
                            </Typography>

                            <div className={"mgl3x"}>
                                {modeForm !== "view" &&
                                <Button label={Config.lang('Chon_nhan_vien')}
                                        startIcon={"Person"}
                                        color={"primary"}
                                        viewType={"outlined"}
                                        style={{textTransform: 'uppercase'}}
                                        disabled={iPermission < 1
                                        || _.isEmpty(shiftID)
                                        || _.isEmpty(attendanceDate)
                                        || isLoadingCheckTime
                                        }
                                        onClick={() => setShowModalEmployeeChosen(true)}
                                />}
                                {modeForm !== "view" &&
                                <Button
                                    className={"mgl2x"}
                                    label={Config.lang('Cap_nhat')}
                                    color={"primary"}
                                    viewType={"filled"}
                                    size={"medium"}
                                    disabled={
                                        _.size(dataGrid) < 1 ||
                                        isErrorDataGrid ||
                                        _.isEmpty(shiftID) ||
                                        modeForm === "view" ||
                                        isLoadingCheckTime
                                    }
                                    onClick={onUpdateGrid}
                                />}
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>

                            <GridContainer
                                reference={ref => gridRef.current = ref}
                                keyExpr={"EmployeeID"}
                                style={{border: "none", overflowX: "scroll"}}
                                dataSource={_.filter(dataGrid, item => _.get(item, "isNew", false) || _.get(item, "Deleted", 0) !== 1)}
                                showRowLines={false}
                                showBorders={false}
                                showColumnLines={false}
                                rowAlternationEnabled={false}
                                noDataText={Config.lang("No_data")}
                                loading={isLoadingGrid || isLoadingCheckTime}
                                hoverStateEnabled={true}
                                height={isExpanded ? 270 : Config.getHeightGrid() - 217}
                                typePaging={"normal"}
                                pagerFullScreen={false}
                                selection={{mode: "none"}}
                                editing={{
                                    mode: 'cell',
                                    refreshMode: "reshape",
                                    texts: {
                                        confirmDeleteMessage: ""
                                    },
                                    allowUpdating: true,
                                    startEditAction: "dblClick"
                                }}
                                onCellSelectionChanged={(e) => {
                                    if (e.selectedRange && e.selectedRange.rowType !== "header") {
                                        selectedRange.current = e.selectedRange;
                                    }
                                }}
                                onEditingStart={e => {
                                    dataFieldEditing.current = e.column.dataField;
                                }}
                                onRowUpdating={onRowUpdating}
                                onRowUpdated={() => {
                                    // xet lại data load api time
                                    dataLoadCheckTime.current = {};
                                    dataCheckOtHour.current = {};
                                    dataFieldEditing.current = "";
                                    allowHeadClick.current = true;
                                }}
                                sorting={{
                                    mode: "none"
                                }}
                                onCellClick={e => {
                                    if(e.rowType === "data") {
                                        selectedRowIndx.current = e.rowIndex;
                                    }
                                }}
                            >
                                <Column
                                    width={80}
                                    alignment={"right"}
                                    allowEditing={false}
                                    cellRender={renderAction}
                                    allowSorting={false}
                                    visible={Config.isMobile}
                                />
                                <Column visible={false} dataField={"EmployeeID"}/>
                                <Column
                                    caption={Config.lang("Nhan_vien")}
                                    allowEditing={false}
                                    cellRender={renderEmployee}
                                    width={250}
                                />
                                <Column visible={false} dataField={"AttendanceDate"}/>
                                <Column dataField={"PreOTFrom"} visible={false}/>
                                <Column dataField={"PreOTTo"} visible={false}/>
                                <Column visible={false} dataField={"IsPreOT"}/>
                                <Column visible={false} dataField={"IsBetOT"}/>
                                <Column visible={false} dataField={"IsAftOT"}/>
                                <Column alignment={"center"}
                                        visible={dataForm.IsPreOT}
                                        dataField={"PreOTFromPreOTTo"}
                                        caption={Config.lang("Tang_ca_truoc")}
                                        editCellRender={(e) =>
                                            renderInputTime(e, "PreOTFrom", "PreOTTo")
                                        }
                                        width={150}
                                        allowEditing={true}
                                        allowCopying={true}
                                        allowPasting={true}
                                        headerCellRender={renderHeaderColumns}
                                >
                                    <AsyncRule
                                        message={Config.lang("Gia_tri_bat_buoc_nhap")}
                                        reevaluate={false}
                                        validationCallback={(e) =>
                                            asyncRuleCellTime(e,
                                                "PreOTFromPreOTTo",
                                                "PreOTFrom",
                                                "PreOTTo",
                                                loadImportOtBefore)}
                                    />
                                </Column>

                                <Column
                                    visible={dataForm.IsPreOT}
                                    caption={Config.lang("Gio_TCT")}
                                    dataField={"PreOTHour"}
                                    headerCellRender={renderHeaderColumns}
                                    allowEditing={true}
                                    allowCopying={true}
                                    allowPasting={true}
                                    cellRender={cellRenderNumberInput}
                                    editCellRender={(e) => renderNumberInput(e, "PreOTHour")}
                                    width={80}>
                                    <AsyncRule
                                        message={Config.lang("Gia_tri_bat_buoc_nhap")}
                                        reevaluate={false}
                                        validationCallback={(e) => asyncRuleCellHour(e, "PreOTHour")} />
                                </Column>

                                <Column alignment={"center"}
                                        visible={dataForm.IsBetOT}
                                        dataField={"BetOTFromBetOTTo"}
                                        caption={Config.lang("Tang_ca_giua")}
                                        editCellRender={(e) =>
                                            renderInputTime(e, "BetOTFrom", "BetOTTo")
                                        }
                                        width={150}
                                        allowEditing={true}
                                        allowCopying={true}
                                        allowPasting={true}
                                        headerCellRender={renderHeaderColumns}
                                >
                                    <AsyncRule
                                        message={Config.lang("Gia_tri_bat_buoc_nhap")}
                                        reevaluate={false}
                                        validationCallback={(e) =>
                                            asyncRuleCellTime(e,
                                                "BetOTFromBetOTTo",
                                                "BetOTFrom",
                                                "BetOTTo",
                                                loadImportOtBet
                                            )}
                                    />
                                </Column>
                                <Column
                                    visible={dataForm.IsBetOT}
                                    caption={Config.lang("Gio_TCG")}
                                    dataField={"BetOTHour"}
                                    headerCellRender={renderHeaderColumns}
                                    allowEditing={true}
                                    allowCopying={true}
                                    allowPasting={true}
                                    cellRender={cellRenderNumberInput}
                                    editCellRender={(e) => renderNumberInput(e, "BetOTHour")}
                                    width={80}>
                                    <AsyncRule
                                        message={Config.lang("Gia_tri_bat_buoc_nhap")}
                                        reevaluate={false}
                                        validationCallback={(e) => asyncRuleCellHour(e, "BetOTHour")} />
                                </Column>
                                <Column alignment={"center"}
                                        visible={dataForm.IsAftOT}
                                        dataField={"AftOTFromAftOTTo"}
                                        caption={Config.lang("Tang_ca_sau")}
                                        editCellRender={(e) =>
                                            renderInputTime(e, "AftOTFrom", "AftOTTo")
                                        }
                                        width={150}
                                        allowEditing={true}
                                        allowCopying={true}
                                        allowPasting={true}
                                        headerCellRender={renderHeaderColumns}
                                >
                                    <AsyncRule
                                        message={Config.lang("Gia_tri_bat_buoc_nhap")}
                                        reevaluate={false}
                                        validationCallback={(e) =>
                                            asyncRuleCellTime(e,
                                                "AftOTFromAftOTTo",
                                                "AftOTFrom",
                                                "AftOTTo",
                                                loadImportOtAfter
                                            )}
                                    />
                                </Column>
                                <Column
                                    visible={dataForm.IsAftOT}
                                    caption={Config.lang("Gio_TCS")}
                                    dataField={"AftOTHour"}
                                    headerCellRender={renderHeaderColumns}
                                    allowEditing={true}
                                    allowCopying={true}
                                    allowPasting={true}
                                    cellRender={cellRenderNumberInput}
                                    editCellRender={(e) => renderNumberInput(e, "AftOTHour")}
                                    width={80}>
                                    <AsyncRule
                                        message={Config.lang("Gia_tri_bat_buoc_nhap")}
                                        reevaluate={false}
                                        validationCallback={(e) => asyncRuleCellHour(e, "AftOTHour")} />
                                </Column>


                                {_.map(dataCaption, (item, i) => <Column
                                    key={i}
                                    caption={_.get(item, "RefCaption", "")}
                                    dataField={_.get(item, "RefID", "")}
                                    headerCellRender={renderHeaderColumns}
                                    dataType={"boolean"}
                                />)}
                                <Column alignment={"center"} allowEditing={false} caption={Config.lang("Loai_tach_ca")}
                                        dataField={"OTTypeName"}/>
                                <Column alignment={"center"} caption={Config.lang("Ghi_chu")}
                                        dataField={"Note"}/>
                                <Column
                                    alignment={"center"}
                                    fixed={true}
                                    fixedPosition={"right"}
                                    allowSorting={false}
                                    visible={!Config.isMobile}
                                    cellRender={renderAction}
                                />
                            </GridContainer>
                            <HelperText
                                hoverTooltip lineClamp={1}>
                                {errors.dataGrid && errors.dataGrid}</HelperText>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary>
                            <span>{Config.lang("Dinh_kem")}</span>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Attachment
                                maxFile={5}
                                maxSize={"5mb"}
                                multiple
                                unitSize={"mb"}
                                disabled={isLoadingForm || iPermission < 1 || isLoadingAttachment || modeForm === "view"}
                                data={dataOldAttachments}
                                onChange={onChangeAttachments}
                                uploadErrorInfo={{
                                    existingFile: Config.lang('File_da_duoc_dinh_kem'),
                                    maxFile: Config.lang('File_vuot_qua_so_luong_cho_phep'),
                                    maxSize: Config.lang('File_vuot_qua_dung_luong_cho_phep'),
                                    fileType : Config.lang("File_khong_dung_dinh_dang"),
                                }}
                            />
                        </AccordionDetails>
                    </Accordion>
                </AccordionGroup>
            </ModalBody>
            {modeForm !== "view" && <ModalFooter>
                <Button label={Config.lang('Luu')}
                        startIcon={"SaveV2"}
                        color={"info"}
                        viewType={"filled"}
                        disabled={
                            isLoadingForm ||
                            isLoadingGrid ||
                            isLoadingOTType ||
                            iPermission < 1 ||
                            isLoadingAttachment ||
                            isErrors ||
                            modeForm === "view" ||
                            isErrorDataGrid ||
                            isLoadingCheckTime
                        }
                        onClick={onStore}
                />
            </ModalFooter>}
        </Modal>
    </>
};

export default compose(connect(
    (state) => ({
        getCboStatus: state.approvals.getCboStatus,
    }),
    dispatch => (
        {
            generalActions: bindActionCreators(generalActions, dispatch),
            W29F2061Actions: bindActionCreators(W29F2061Actions, dispatch),
            approvalActions: bindActionCreators(ApprovalActions, dispatch),
        }
    )))(W29F2061);
