/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 1/28/2021
 * @Example
 */

import React, {useState, useEffect, useRef}                         from "react";
import { compose, bindActionCreators }           from "redux";
import { connect }                               from "react-redux";
import Modal                                     from "../../../common/modal/modal";
import {Col, Row, FormGroup, Image} from "react-bootstrap";
import {
    makeStyles,
    FormControl,
    FormHelperText,
    Input,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails
} from "@material-ui/core";
import Config                                    from "../../../../config";
import {Combo, MForm}                            from "../../../common/form-material";
import ButtonGeneral                             from "../../../common/button/button-general";
import { FormControlLabel, Checkbox, TextField } from "@material-ui/core";
import * as W29F2055Actions                      from "../../../../redux/W2X/W29F2055/W29F2055_actions";
import * as generalActions                       from "../../../../redux/general/general_actions";
import Icons                                     from "../../../common/icons";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import W09F6000 from "../../../W0X/W09/W09F6000/W09F6000";
import InputMask from "react-input-mask";
import _ from "lodash";
import {Column} from "devextreme-react/data-grid";
import GridContainer from "../../../grid-container/grid-container";
import GridActionBar from "../../../grid-container/grid-actionbar";
import moment from "moment";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import CloseIcon from '@material-ui/icons/Close';
import Attachments from "../../../common/attachments/attachments";
import InlineSVG from "react-inlinesvg";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import W29F2056Popup from "../W29F2056/W29F2056";
import History from "../../../libs/history";
import {DatePicker} from "diginet-core-ui/components";
const useStyles = makeStyles(theme => ({
    divAvatar: {
        width: 46,
        height: 46,
        minWidth: 46,
        minHeight: 46,
        borderRadius: "50%",
        overflow: "hidden",
        marginRight: 8
    },
    imgAvatar: {
        maxWidth: "100%",
        objectFit: "contain"
    },
    noneBorder: {
        margin:'0px!important',
        "&:before": {
            backgroundColor: "transparent"
        }
    },
    ExpansionPanelSummary: {
        padding: 0,
        maxWidth: 300,
        color: theme.palette.primary.main,
        "& .MuiExpansionPanelSummary-content": {
            margin: "12px 0"
        },
        "&.Mui-expanded": {
            minHeight: "inherit",
        },
    },
    rmUnderLine: {
        "& .MuiInput-underline:before": {
            content: "none",
        },
        "& .MuiInput-underline:after": {
            content: "none",
        },
    },
    cboUnderline: {
        "& .dx-texteditor.dx-editor-underlined.dx-state-readonly:after": {
            borderBottomStyle: "double"
        }
    },
    btnStyle: {
        padding: 0,
        width: 30,
        height: 30,
        marginRight: 4
    },
    bgRow: {
        "& td": {
            opacity: 0.6
        }
    }
}));

const W29F2055Popup = (props) => {
    const classes = useStyles();

    const _initDataForm = {
        ShiftID: "",
        OTHour: "",
        GroupID: "",
        TransID: "",
        AttendanceDate: null,
        OTFrom: "",
        OTTo: "",
        OTTypeID: "",
        OTTypeName: "",
        RefID: "",
        ReasonOT: "",
        Note: "",
    };

    const _initDataGroupEmployee = {
        total: 0,
        rows: []
    };

    const _initDataCheckCaption = {
        IsConfirm01: 0,
        IsConfirm02: 0,
        IsConfirm03: 0,
        IsConfirm04: 0,
        IsConfirm05: 0,
    };

    const {data, open, iPermission, onClose} = props;
    const { modeW29F2050, rowData } = data || {};
    const [errorHelperText, setErrorHelperText] = useState({});
    const [isReadOnly, setIsReadOnly] = useState(false);
    const [isModeView, setIsModeView] = useState(false);
    const [dataForm, setDataForm] = useState(_initDataForm);
    const [dataGridEmployee, setDataGridEmployee] = useState([]);
    const [isShowModalW09F6000, setIsShowModalW09F6000] = useState(false);
    const [isShowModalW29F2056, setIsShowModalW29F2056] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [dataOldAttachments, setDataOldAttachments] = useState([]);
    const [expandedFirst, setExpandedFirst] = useState(true);
    const [dataPopUpW29F2056, setDataPopUpW29F2056] = useState({mode: "", rowData: {}});
    const [loading, _setLoading] = useState({cboOtType: false, loadingDataGrid: false,
            loadingAttachment: false, loadingCaption: false, loadingParamOtType: false});
    const [dataCboOtType, setDataCboOtType] = useState([]);
    const [paramOtType, setParamOtType] = useState({});
    const [dataCaption, setDataCaption] = useState([]);
    const [dataCheckboxCaption, setCheckboxCaption] = useState(_initDataCheckCaption);
    const [cboGroupEmployee, setCboGroupEmployee] = useState(_initDataGroupEmployee);
    let dataGrid = useRef(null);
    const attRef = useRef(null);
    let attachments = useRef([]);
    let deletedFile = useRef([]);
    let isChange = useRef(false);
    let dataOldEmployee = useRef([]);
    let dataOldMaster = useRef([]);
    let setCursorOTFrom = useRef(false);
    let setCursorOTTo = useRef(false);
    let setTypeOTFrom = useRef(false);
    let setTypeOTTo = useRef(false);

    const loadCboOtType = () => {
        const params = {
            AttendanceDate: dataForm && dataForm.AttendanceDate ? moment(dataForm.AttendanceDate).format("YYYY-MM-DD") : null,
            OTFrom: dataForm?.OTFrom || "",
            OTTo: dataForm?.OTTo || "",
            EmployeeID: "",
            ShiftID: dataForm?.ShiftID || "",
            Language: Config.language || 84,
        };
        setLoading({cboOtType: true});
        props.w29f2055Actions.getCboOtType(params, (error, data) => {
        setLoading({cboOtType: false});
            if (error) {
                Config.popup.show('ERROR', error);
                return false;
            }
            if(data) {
                setDataCboOtType(data);
            }
        });
    };

    const loadCaption = () => {
        setLoading({loadingCaption: true});
        props.w29f2055Actions.getCaption((error, data) => {
            setLoading({loadingCaption: false});
            if (error) {
                Config.popup.show('ERROR', error);
                return false;
            }
            if(data) {
                setDataCaption(data);
            }
        });
    };

    const loadParamOtType = () => {
        setLoading({loadingParamOtType: true});
        props.w29f2055Actions.getParamOtType((error, data) => {
            setLoading({loadingParamOtType: false});
            if (error) {
                Config.popup.show('ERROR', error);
                return false;
            }
            if(data) {
                setParamOtType(data);
            }
        });
    };

    const loadAttachments = () => {
        const {TransID} = rowData;
        const param = {
            KeyID: TransID || "",
            TableName: "D75T4074"
        };
        setLoading({loadingAttachment: true});
        props.generalActions.getAttachmentsByTransID(param, (error, data) => {
            setLoading({loadingAttachment: false});
            if (error) {
                Config.popup.show('ERROR', error);
                return false;
            }
            if (data && data.length > 0) {
                setDataOldAttachments(data);
            }
        });
    };

    const loadCboChooseGroupEmployee = () => {
        const dataChecked = convertBooleanToNumber(dataCheckboxCaption) || 0;
        const params = {
            ...dataForm,
            ...dataChecked,
            AttendanceDate: dataForm.AttendanceDate ? moment(dataForm.AttendanceDate, "YYYY/MM/DD").format("YYYY-MM-DD") : null,
        };
        props.w29f2055Actions.getCboChooseGroupEmployee(params,(error,data) => {
            if (error) {
                Config.popup.show('ERROR', error);
                return false;
            }
            if(data) {
                const dataList = data.map(item => {return item.EmployeeID});
                const users = Config.getListUser(dataList);
                users.forEach(item=>{
                   const temp = data.find( i => item.EmployeeID === i.EmployeeID);
                   if(temp){
                       item = Object.assign(item, temp);
                   }
                });
                const dataUser = JSON.parse(JSON.stringify(users));
                const dataGrid = getDataFilterGrid(dataUser,dataGridEmployee);
                setDataGridEmployee(dataGrid);
            }
        });
    };

    const loadCboGroupEmployee = () => {
        const params = {
            FormID: "W29F2050",
            Language: Config.language || 84,
            Mode: 0,
            skip: 0,
            limit: 50,
            search: "W29F2050",
            search2: ""
        };
        props.w29f2055Actions.getCboGroupEmployee(params,(error,data) => {
            if (error) {
                Config.popup.show('ERROR', error);
                return false;
            }
            if(data) {
                setCboGroupEmployee({
                    total: data.total,
                    rows: data.rows
                });
            }
        });
    };

    const loadFormMaster = () => {
        const {AttendanceDate,ShiftID,TransID} = rowData;
        const params = {
            FormID: "W29F2050",
            TransID: TransID || null,
            AttendanceDate: AttendanceDate ? moment(AttendanceDate).format("YYYY-MM-DD") : null,
            OTFrom: "",
            OTTo: "",
            EmployeeID: "",
            ShiftID: ShiftID || null,
            Language: Config.language || 84,
            Mode: 0,
        };

        props.w29f2055Actions.getForm(params, (error,data) => {
            if (error) {
                Config.popup.show('ERROR', error);
                return false;
            }
            if(data && data.length > 0) {
                if(modeW29F2050 === "edit") {
                    dataOldMaster.current = {
                        TransID: data[0].TransID,
                        OTFrom: data[0].OTFrom,
                        OTTo: data[0].OTTo,
                        OTTypeID: data[0].OTTypeID,
                        OTTypeName: data[0].OTTypeName,
                        ShiftID: data[0].ShiftID,
                        AttendanceDate: Config.convertDate(data[0].AttendanceDate, null, "YYYY-MM-DD"),
                        ReasonOT: data[0].ReasonOT,
                        Note: data[0].Note,
                        GroupID: "",
                        IsConfirm01: Number(data[0].IsConfirm01),
                        IsConfirm02: Number(data[0].IsConfirm02),
                        IsConfirm03: Number(data[0].IsConfirm03),
                        IsConfirm04: Number(data[0].IsConfirm04),
                        IsConfirm05: Number(data[0].IsConfirm05),
                        IsOverTime: data[0].IsOverTime
                    };
                }
                setDataForm({
                    TransID: data[0].TransID,
                    OTFrom: data[0].OTFrom,
                    OTTo: data[0].OTTo,
                    OTTypeID: data[0].OTTypeID,
                    OTTypeName: data[0].OTTypeName,
                    AttendanceDate: Config.convertDate(data[0].AttendanceDate, null, "YYYY-MM-DD"),
                    ReasonOT: data[0].ReasonOT,
                    Note: data[0].Note,
                    GroupID: "",
                });
                setCheckboxCaption({
                    IsConfirm01: data[0].IsConfirm01,
                    IsConfirm02: data[0].IsConfirm02,
                    IsConfirm03: data[0].IsConfirm03,
                    IsConfirm04: data[0].IsConfirm04,
                    IsConfirm05: data[0].IsConfirm05,
                })
            }
        });
    };

    const loadGridEmployee = (dataGrid = []) => {
        const {AttendanceDate,ShiftID,TransID} = rowData;
        const params = {
            FormID: "W29F2050",
            TransID: TransID || null,
            AttendanceDate: AttendanceDate || null,
            OTFrom: "",
            OTTo: "",
            EmployeeID: "",
            ShiftID: ShiftID,
            Language: Config.language || 84,
            Mode: 1,
        };

        props.w29f2055Actions.getForm(params, (error,data) => {
            if (error) {
                Config.popup.show('ERROR', error);
                return false;
            }
            if(data) {
                if(modeW29F2050 === "edit") {
                    dataOldEmployee.current = data.map(d => {
                        if(_.isBoolean(d.IsConfirm01)) d.IsConfirm01 = Number(d.IsConfirm01);
                        if(_.isBoolean(d.IsConfirm02)) d.IsConfirm02 = Number(d.IsConfirm02);
                        if(_.isBoolean(d.IsConfirm03)) d.IsConfirm03 = Number(d.IsConfirm03);
                        if(_.isBoolean(d.IsConfirm04)) d.IsConfirm04 = Number(d.IsConfirm04);
                        if(_.isBoolean(d.IsConfirm05)) d.IsConfirm05 = Number(d.IsConfirm05);
                        return {...d};
                    });
                    if(dataGrid.length > 0) {
                      data.push(...dataGrid);
                    }
                }
                setDataGridEmployee(data);
            }
        });
    };

    useEffect(() => {
        if(modeW29F2050 === "add") {
            loadCboGroupEmployee();
            loadCboOtType();
            loadParamOtType();
            loadCaption();
        }
        if(modeW29F2050 === "edit") {
            setIsReadOnly(true);
            loadCboGroupEmployee();
            loadCaption();
            loadAttachments();
            loadFormMaster();
            loadGridEmployee();
            loadCboOtType();
            loadParamOtType();
        }
        if(modeW29F2050 === "view") {
            setIsReadOnly(true);
            setIsModeView(true);
            loadFormMaster();
            loadGridEmployee();
            loadCaption();
            loadAttachments();
            loadCboOtType();
            loadParamOtType();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[data?.modeW29F2050]);

    useEffect(() => {
        if(dataForm.AttendanceDate === null || isChange.current) {
            isChange.current = false;
            setCursorOTFrom.current = false;
            setCursorOTTo.current = false;
            setTypeOTFrom.current = false;
            setTypeOTTo.current = false;
            setData({OTFrom: "",OTTo: ""});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[dataForm.AttendanceDate]);

    useEffect(() => {
        if(!setCursorOTFrom.current && dataForm.OTFrom && !dataForm.OTFrom.includes("_")) {
            setTimeout(() => {
                moveCursor('cursorOTFrom',dataForm.OTFrom);
            }, 100)
        }

        if(!setCursorOTTo.current && dataForm.OTTo && !dataForm.OTTo.includes("_")) {
            setTimeout(() => {
                moveCursor('cursorOTTo', dataForm.OTTo);
            }, 100)
        }

        checkOtFromEmpty(dataForm.OTFrom);
        checkOttoEmpty(dataForm.OTTo);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataForm.OTFrom, dataForm.OTTo]);

    useEffect(() => {
        if(dataForm && dataForm.GroupID !== "") {
            loadCboChooseGroupEmployee();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[dataForm.GroupID]);

    const checkChangeTime = (data) => {
        let test = data.split('');
        return test.every((a, i) => {
            if (i === 0) {
                return !isNaN(a);
            }
            return isNaN(a);
        })
    };

    const CheckDataWhenSelection = (data) => {
        let test = data.split('');
        return test.every((a, i) => {
            if (i === 2) {
                return typeof (a) === 'string'
            }
            return !isNaN(a);

        })};

    const moveCursor = (id,data) => {
        let check = false;
        if(data.charAt(0) <= 2 && data.charAt(1) <= 2 && CheckDataWhenSelection(data)) {
            check = true;
        }
        if(id === "cursorOTFrom") {
            setCursorOTFrom.current = true;
        }
        if(id === "cursorOTTo") {
            setCursorOTTo.current = true;
        }
        let el = document.getElementById(id);
        el.setSelectionRange(check ? 1 : 3,check ? 1 : 3);
    };

    const checkOtFromEmpty = (data) => {
        if(!data) return false;
        if(data.includes("_")) {
            const arr = data.split('');
            const dataFilter = arr.filter(item => item === "_");
            if(dataFilter.length >= 4) {
                setCursorOTFrom.current = false;
                setTypeOTFrom.current = false;
            }
        }
    };

    const checkOttoEmpty = (data) => {
        if(!data) return false;
        if(data.includes("_")) {
            const arr = data.split('');
            const dataFilter = arr.filter(item => item === "_");
            if(dataFilter.length >= 4) {
                setCursorOTTo.current = false;
                setTypeOTTo.current = false;
            }
        }
    };

    const resetForm = () => {
        setDataForm({
            GroupID: "",
            OTHour: "",
            TransID: "",
            AttendanceDate: null,
            OTFrom: "",
            OTTo: "",
            OTTypeID: "",
            OTTypeName: "",
            RefID: "",
            ReasonOT: "",
            Note: "",
        });
        setCheckboxCaption({
            IsConfirm01: 0,
            IsConfirm02: 0,
            IsConfirm03: 0,
            IsConfirm04: 0,
            IsConfirm05: 0,
        });
        setDataGridEmployee([]);
        attachments = [];
        deletedFile = [];
        dataGrid = null;
    };

    const onClosePopup = (isReload = false) => {
        if(onClose) onClose(isReload);
    };

    const setStateErrorText = (objValue) => {
        if(objValue) {
            setErrorHelperText({
                ...errorHelperText,
                ...objValue
            });
            return Object.keys(objValue).length !== 0;
        } else {
            setErrorHelperText({});
            return false;
        }
    };

    const setLoading = (obj) => {
        _setLoading({...loading, ...obj});
    };

    const setData = (obj) => {
        setDataForm({...dataForm,...obj});
    };

    const handleCheckbox = (key,value) => {
        setCheckboxCaption({...dataCheckboxCaption,[key]: value})
    };

    const openEmployeeSelect = () => {
        setIsShowModalW09F6000(true);
    };

    const getDataFilterGrid = (dataUser,dataGridEmployee) => {
        let dataGrid = [];
        if(dataGridEmployee.length > 0) {
            let dataFilter = dataGridEmployee.map(item => { return item.EmployeeID });
            dataGrid = dataUser.filter(f => !dataFilter.includes(f.EmployeeID));
        }
        dataGrid = dataGridEmployee && dataGridEmployee.length > 0 ? dataGridEmployee.concat(dataGrid) : dataUser;

        return  addParamArray(dataGrid) || [];
    };

    const addParamArray = (data) => {
        if(!data) return false;
        data.forEach(item => {
            if(!item.LinkTransID) item.LinkTransID = null;
            if(!item.AttendanceDate) item.AttendanceDate = null;
            if(!item.ShiftID) item.ShiftID = "";
            if(!item.TransID) item.TransID = "";
            if(!item.OTHour) item.OTHour = "";
            if(!item.OTFrom) item.OTFrom = null;
            if(!item.OTTo) item.OTTo = null;
            if(!item.IsOverTime) item.IsOverTime = 0;
            if(!item.ShiftInOut) item.ShiftInOut = null;
            if(!item.RefID) item.RefID = null;
            if(!item.OTTypeID) item.OTTypeID = null;
            if(!item.OTTypeName) item.OTTypeName = null;
            if(!item.Note) item.Note = "";
            if(!item.IsConfirm01) item.IsConfirm01 = 0;
            if(!item.IsConfirm02) item.IsConfirm02 = 0;
            if(!item.IsConfirm03) item.IsConfirm03 = 0;
            if(!item.IsConfirm04) item.IsConfirm04 = 0;
            if(!item.IsConfirm05) item.IsConfirm05 = 0;
            if(!item.IsUpdate) item.IsUpdate = 0;
            if(!item.Deleted) item.Deleted = 0;
            if(item?.AddEmp === 0) item.AddEmp = 0;
        });
        return data;
    };

    const onSelectedEmployee = (e) => {
        const dataChecked = convertBooleanToNumber(dataCheckboxCaption) || 0;
        const params ={
                AttendanceDate: dataForm && dataForm.AttendanceDate ? moment(dataForm.AttendanceDate).format("YYYY-MM-DD") : null,
                OTFrom: dataForm?.OTFrom || "",
                OTTo: dataForm?.OTTo || "",
                OTTypeID: dataForm?.OTTypeID || "",
                Note: dataForm?.Note || "",
                ...dataChecked,
        };

        props.w29f2055Actions.getEmployee(params, (err, data)=>{
            if(err){
                Config.popup.show("ERROR", err);
                return false;
            }
            data.forEach(i=>{
                i.AddEmp = 0;
                i.AttendanceDate = dataForm?.AttendanceDate;
            });
            const dataGrid = getDataFilterGrid(data,dataGridEmployee);
            setDataGridEmployee(dataGrid);
            setIsShowModalW09F6000(false);
        });
    };

    const closeEmployeeSelect = () => {
        setIsShowModalW09F6000(false);
    };

    const addStr = (str, index, stringToAdd) => {
        return str.substring(0, index) + stringToAdd + str.substring(index, str.length);
    };

    const closePopupW29F2056 = (data,isLoaded,mode) => {
        if(mode === "add") {
            dataGridEmployee.forEach(item => {
                if(item.EmployeeID === data.EmployeeID) {
                    item.OTFrom = data?.OTFrom ? addStr(data.OTFrom, 2, ":")  : item.OTFrom || "";
                    item.OTTo = data?.OTTo ? addStr(data.OTTo, 2, ":") : item.OTTo || "";
                    item.OTHour  = data?.OTHour || item.OTHour || "";
                    item.OTTypeName  = data?.OTTypeName || item.OTTypeName || "";
                    item.Note  = data?.Note || item.Note || "";
                    item.IsOverTime = data?.IsOverTime || 0;
                    item.IsConfirm01 = data?.IsConfirm01 || 0;
                    item.IsConfirm02 = data?.IsConfirm02 || 0;
                    item.IsConfirm03 = data?.IsConfirm03 || 0;
                    item.IsConfirm04 = data?.IsConfirm04 || 0;
                    item.IsConfirm05 = data?.IsConfirm05 || 0;
                    item.OTTypeID = data?.OTTypeID || item.OTTypeID || "";
                    item.IsUpdate = data?.IsUpdate || 0;
                    item.Deleted = data?.Deleted || 0;
                }
            });
        }
        if(mode === "edit") {
            const dataGrid = dataGridEmployee.filter(item => item.TransID === "" && item.Deleted !== 1);
            loadGridEmployee(dataGrid);
        }

        setIsShowModalW29F2056(false);
    };

    const onAttachment = () => {
        if (attRef) {
            attRef.onAttachment();
        }
    };

    const onUploading = (value) => {
        setUploading(value);
    };

    const onChangeAttachments = (e) => {
        attachments.current = e.uploadedFiles ? e.uploadedFiles : [];
        if (e.deletedFiles && e.deletedFiles.length > 0) {
            deletedFile = [ ...e.deletedFiles];
            const _arrRemove = deletedFile.map((d) => d.AttachmentID);
            const dataNew = [...dataOldAttachments].filter(att=>_arrRemove.indexOf(att.AttachmentID) < 0);
            setDataOldAttachments(dataNew);
        }
    };

    const _getAttachments = () => {
        const listAttachments = Config.helpers.getFileInfomations(attachments.current);
        let arrAttachment = [];
        listAttachments.forEach((att) => {
            arrAttachment.push({
                URL: att.url ? att.url : "",
                FileName: att.fileName ? att.fileName : "",
                FileSize: att.fileSize ? att.fileSize : "",
                FileExt: att.fileExt ? att.fileExt : ""
            });
        });
        if (dataOldAttachments && dataOldAttachments.length > 0) {
            arrAttachment = dataOldAttachments.concat(arrAttachment);
        }

        return arrAttachment;
    };

    const handleChange = async (key,e) => {
        if(!key) return false;
        const value = _.get(e, 'value', _.get(e, 'target.value', ''));
        const selection = window.getSelection();
        if (key === "OTFrom") {
            if (!value.split("").includes("_")) {
                setStateErrorText({errorMask: ""});
            }
        }
        if (key === "OTTo") {
            if (!value.split("").includes("_")) {
                setStateErrorText({errorMask: ""});
            }
        }

        switch (key) {
            case "AttendanceDate":
                setData({AttendanceDate: value});
                if (value !== dataForm?.[key]) isChange.current = true;
                break;
            case "OTTypeID":
                setData({OTTypeID: value});
                break;
            case "GroupID":
                setData({GroupID: value});
                break;
            case "OTFrom":
                if(value.charAt(0) > 2 && CheckDataWhenSelection(value)) {
                    return;
                }
                if(selection && !setTypeOTFrom.current && checkChangeTime(value)) {
                    if(selection.focusNode.childNodes[0].id === "cursorOTFrom") {
                        setCursorOTFrom.current = false;
                        await setDataTime("OTFrom", value);
                    }
                } else {
                    setData({OTFrom:  value});
                    setTypeOTFrom.current = true;
                }
                break;
            case "OTTo":
                if(value.charAt(0) > 2 && CheckDataWhenSelection(value)) {
                    return;
                }
                if(selection && !setTypeOTTo.current && checkChangeTime(value)) {
                    if(selection.focusNode.childNodes[0].id === "cursorOTTo") {
                        setCursorOTTo.current = false;
                        await setDataTime("OTTo", value);
                    }
                } else {
                    setData({OTTo: value});
                    setTypeOTTo.current = true;
                }
                break;
            case "AttendanceAmount":
                setData({AttendanceAmount: value});
                break;
            case "ReasonOT":
                setData({ReasonOT: value});
                break;
            case "Note":
                setData({Note: value});
                break;
            default:
                break;
        }
    };
    const swap = (arr,x,y) => {
        let tmp = arr[x];
        arr[x] = arr[y];
        arr[y] = tmp;
        return arr;
    };

    const setDataTime = (key,data) => {
        if(!key || !data) return false;
        let arr = data.split("");
        if(arr && arr.length > 0) {
            if(Number(arr[0]) >= 3){
                arr = swap(arr,0,1);
                for(let i = 0; i < arr.length; i++){
                    arr[i] = arr[i] === "_" ? "0" : arr[i];
                }
            } else {
                for(let i = 0; i < arr.length; i++){
                    arr[i] = arr[i] === "_" ? "0" : arr[i];
                }
            }
        }
        if(key === "OTFrom") {
            setData({OTFrom: arr.join('')});
            if(!setCursorOTFrom.current) {
                setTimeout(() => {
                    moveCursor('cursorOTFrom', arr.join(''));
                }, 100)
            }
        }
        if(key === "OTTo") {
            setData({OTTo: arr.join('')});
            if(!setCursorOTTo.current) {
                setTimeout(() => {
                    moveCursor('cursorOTTo', arr.join(''));
                }, 100)
            }
        }
    };

    const convertBooleanToNumber = (data) => {
        if(!data) return false;
        const dataConvert = {};
        if(_.isObject(data)) {
            for (let [key, value] of Object.entries(data)) {
                if(_.isBoolean(value)) {
                    value = Number(value);
                }
                dataConvert[key] = value;
            }
        }
        return dataConvert;
    };

    const compareUpdateArray = (dataGridEmployee,data) => {
        return dataGridEmployee.map(item => {
            let dataFilter = data.find(f => f.EmployeeID === item.EmployeeID);
            if(dataFilter) {
                item.AttendanceDate = dataFilter?.AttendanceDate || null;
                item.Deleted = dataFilter?.Deleted || 0;
                item.IsConfirm01 = dataFilter?.IsConfirm01 || 0;
                item.IsConfirm02 = dataFilter?.IsConfirm02 || 0;
                item.IsConfirm03 = dataFilter?.IsConfirm03 || 0;
                item.IsConfirm04 = dataFilter?.IsConfirm04 || 0;
                item.IsConfirm05 = dataFilter?.IsConfirm05 || 0;
                item.IsUpdate = dataFilter?.IsUpdate || 0;
                item.OTFrom = dataFilter?.OTFrom || "";
                item.OTTo = dataFilter?.OTTo || "";
                item.OTTypeID = dataFilter?.OTTypeID || "";
                item.OTTypeName = dataFilter?.OTTypeName || "";
                item.ShiftID = dataFilter?.ShiftID || null;
                item.OTHour = dataFilter?.OTHour || "";
                item.ShiftInOut = dataFilter?.ShiftInOut || null;
                item.Note = dataFilter?.Note || "";
            }
            return item;
        });
    };

    const _onUpdate = () => {
        let dataSource = [];
        if(dataGrid) {
            dataSource = dataGrid.instance.option("dataSource");
            dataSource = dataSource.filter(item => item.Deleted !== 1);
        }

        const checkAddEmp = dataSource.find(i=>i.AddEmp !== 1);
        if(checkAddEmp){
            Config.popup.show("YES_NO", Config.lang('Ban_co_muon_cap_nhat_thong_tin_tang_ca_nhan_vien'),
                //Choosen All
                () => { //onConfirm
                    onUpdate(dataSource, 0);
                },
                //Choosen New
                ()=>{
                    onUpdate(dataSource, 1);
                },
            null, null, Config.lang("Tat_ca"), Config.lang("Moi_bo_sung"))
        }
        else{
            Config.popup.show("YES_NO", Config.lang('Ban_co_muon_cap_nhat_thong_tin_tang_ca_nhan_vien'),
                //Choosen All
                () => { //onConfirm
                    onUpdate(dataSource);
                })
        }
    };

    const onUpdate = (dataSource, choise) => {
        const dataChecked = convertBooleanToNumber(dataCheckboxCaption) || 0;
        const params = {
            OTFrom: dataForm && dataForm.OTFrom ? dataForm.OTFrom.replace(":", "") : "",
            OTTo: dataForm && dataForm.OTTo ? dataForm.OTTo.replace(":", "") : "",
            AttendanceDate: dataForm && dataForm.AttendanceDate ? moment(dataForm.AttendanceDate).format("YYYY-MM-DD") : null,
            OTTypeID: dataForm?.OTTypeID || "",
            Note: dataForm.Note,
            data: dataSource ? JSON.stringify(formatDateDataSource(dataSource)) : "",
            Choice : typeof choise !== "undefined" ? choise : null,
            ...dataChecked
        };
        props.w29f2055Actions.update(params, (errors, data) => {
            if (errors) {
                let message = errors.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show('ERROR', message);
                return false;
            }
            if(data && data.length > 0) {
                const dataGrid = compareUpdateArray(dataGridEmployee, data);
                dataGrid.forEach(i=>i.AddEmp = 1);
                setData({
                    ShiftID: data[0].ShiftID,
                    OTHour: data[0].OTHour,
                });
                setDataGridEmployee(dataGrid);
            }
        })
    };

    const allEqual = (data) => {
        const valueArr = data.map((item) => { return item.ShiftID });
        return valueArr.every( v => {
            if(v === null) {
                return true;
            } else {
                return v === valueArr[0];
            }
        })
    };

    const checkNullValue = (data) => {
        return data.some(item => !item.ShiftID);
    };

    const formatDateDataSource = (data) => {
        let dataFormat = data.map(item => {
            item.AttendanceDate = item.AttendanceDate ? moment(item.AttendanceDate).format("YYYY-MM-DD") : null;
            if(_.isBoolean(item.IsConfirm01)) item.IsConfirm01 = Number(item.IsConfirm01);
            if(_.isBoolean(item.IsConfirm02)) item.IsConfirm02 = Number(item.IsConfirm02);
            if(_.isBoolean(item.IsConfirm03)) item.IsConfirm03 = Number(item.IsConfirm03);
            if(_.isBoolean(item.IsConfirm04)) item.IsConfirm04 = Number(item.IsConfirm04);
            if(_.isBoolean(item.IsConfirm05)) item.IsConfirm05 = Number(item.IsConfirm05);

            return item;
        });
        return addParamArray(dataFormat);
    };

    const onSave = () => {
        let actionSaveMode = modeW29F2050 === "add" ? "add" : "edit";
        dataGrid.instance.saveEditData();
        const dataSource = dataGrid.instance.option("dataSource");
        const validateRules = [
            {
                name: "AttendanceDate",
                rules: ["isRequired"],
                value: dataForm.AttendanceDate || ""
            },
            {
                name: "OTFrom",
                rules: ["isRequired"],
                value: dataForm.OTFrom && !dataForm.OTFrom.split("").includes("_") ? dataForm.OTFrom : ""
            },
            {
                name: "OTTo",
                rules: ["isRequired"],
                value: dataForm.OTTo && !dataForm.OTTo.split("").includes("_") ? dataForm.OTTo : ""
            },


        ];
        const validateForm = MForm.formValidation(validateRules);
        if (Object.keys(validateForm).length > 0) {
            setStateErrorText(validateForm);
            if(setStateErrorText(validateForm)) return false;
        }

        if(errorHelperText['errorTime']) {
            return
        }

        if(dataSource){
            let dataCheck = JSON.parse(JSON.stringify(dataSource));
            dataCheck = dataCheck.filter(item => item.Deleted !== 1);
            if(!allEqual(dataCheck)) {
                let message = Config.lang("Ton_tai_nhieu_hon_1_ca_lam_viec_trong_cung_mot_ngay_lam_viec.Ban_khong_duoc_phep_luu");
                Config.popup.show('ERROR', message);
                return false;
            }
            if(checkNullValue(dataCheck)) {
                let message = Config.lang("Ton_tai_nhan_vien_chua_co_ca_lam_viec.Ban_khong_duoc_phep_luu");
                Config.popup.show('ERROR', message);
                return false;
            }
        }

        const arrAttachments = _getAttachments();
        const dataChecked = convertBooleanToNumber(dataCheckboxCaption) || 0;
        const params = {
            TransID: rowData?.TransID || "",
            ShiftID: dataForm?.ShiftID || rowData?.ShiftID || "",
            OTFrom: dataForm?.OTFrom || "",
            OTTo: dataForm?.OTTo || "",
            Language: Config.language || 84,
            AttendanceDate: dataForm && dataForm.AttendanceDate ? moment(dataForm.AttendanceDate).format("YYYY-MM-DD") : null,
            OTTypeID: dataForm?.OTTypeID || "",
            ReasonOT: dataForm?.ReasonOT || "",
            Note: dataForm?.Note || "",
            data: dataSource ? JSON.stringify(formatDateDataSource(dataSource)) : "",
            arrAttachment: JSON.stringify(arrAttachments),
            ...dataChecked
        };
        props.w29f2055Actions[actionSaveMode](params, async (errors, data) => {
            if (errors) {
                Config.popup.show('ERROR', errors);
                return false;
            }
            if (data.Status === 1) {
                    let message = data.Message || Config.lang("Loi_chua_xac_dinh");
                    Config.popup.show('INFO', message);
                    return false;
            }
            if (data.Status === 0) {
                Config.notify.show('success', Config.lang("Luu_thanh_cong"), 2000);
                await saveHistory(params, actionSaveMode, data.TransID);
                resetForm();
                onClosePopup(true);
            }
        })

    };

    const saveHistory = async (data, mode, _TransID) => {
        if(!data) return false;
        const TransID = mode === "add" ? _TransID :  _.get(data, "TransID", "");
        const _mode = mode === "add" ? 0 : 1;
        let dataSource = dataGrid.instance.option('dataSource');
        let OTTypeID =_.get(data, "OTTypeID", "");
        let OtTypeName = dataCboOtType.find(item => item.OTTypeID === OTTypeID);
        if (TransID) {
            // data Content
            const _data = {
                AttendanceDate: _.get(data, "AttendanceDate", ""),
                OTFrom: _.get(data, "OTFrom", ""),
                OTTo: _.get(data, "OTTo", ""),
                OTTypeName: _.get(OtTypeName, "OTTypeName", ""),
                ShiftID: _.get(data, "ShiftID", ""),
                ReasonOT: _.get(data, "ReasonOT", ""),
                Note: _.get(data, "Note", ""),
                OTTypeID: OTTypeID,
                IsConfirm01: Number(_.get(data, "IsConfirm01", 0)),
                IsConfirm02: Number(_.get(data, "IsConfirm02", 0)),
                IsConfirm03: Number(_.get(data, "IsConfirm03", 0)),
                IsConfirm04: Number( _.get(data, "IsConfirm04", 0)),
                IsConfirm05: Number(_.get(data, "IsConfirm05", 0)),
                IsOverTime: Number(_.get(rowData, "IsOverTime", 0)),
            };

            //name Content
            const captions = {
                AttendanceDate: "Ngay_cong",
                OTFrom: "Tang_ca_tu",
                OTTo: "Tang_ca_den",
                OTTypeName: "Loai_tach_tang_ca",
                ShiftID: "data_ShiftID",
                ReasonOT: "Ly_do_tang_ca",
                Note: "Ghi_chu",
                OTTypeID: "data_OTTypeID",
                IsConfirm01: "data_IsConfirm01",
                IsConfirm02: "data_IsConfirm02",
                IsConfirm03: "data_IsConfirm03",
                IsConfirm04: "data_IsConfirm04",
                IsConfirm05: "data_IsConfirm05",
                IsOverTime: "data_IsOverTime",
            };
            // name detail grid
            let detailCaptions = {
                TransID: "TransID",
                EmployeeID: "Ma_nhan_vien",
                EmployeeName: "Ten_nhan_vien",
                OTFrom: "Tang_ca_tu",
                OTTo: "Tang_ca_den",
                ShiftInOut: "Ca_vao_ra",
                OTTypeName: "Loai_tach_tang_ca",
                Note: "Ghi_chu",
                IsConfirm01: "data_IsConfirm01",
                IsConfirm02: "data_IsConfirm02",
                IsConfirm03: "data_IsConfirm03",
                IsConfirm04: "data_IsConfirm04",
                IsConfirm05: "data_IsConfirm05",
                ShiftID: "data_ShiftID",
                OTTypeID: "data_OTTypeID",
                IsOverTime: "data_IsOverTime",
            };
            // data form master
            const _dataOld = dataOldMaster.current;

            const options = {
                data: _data,
                captions: captions,
                dataCompare: _dataOld,
                action: _mode,
                ModuleID: "D29",
                TransID: TransID,
                TransactionID: "W29F2050",
                TransactionName: "Lập lịch tăng ca",
            };
            const history = new History(options); //Init history
            history.createDetailHistory("Nhan_vien", dataSource, dataOldEmployee.current, detailCaptions,"EmployeeID",  null, options ); //Create detail..
            if (await history.save()) {
                Config.notify.show("success", Config.lang("Luu_lich_su_thanh_cong"), 2000);
            } else {
                Config.notify.show("error", Config.lang("Luu_lich_su_khong_thanh_cong"), 2000);
            }
        } else {
            Config.notify.show("error", Config.lang("Luu_lich_su_khong_thanh_cong"), 2000);
        }

    };


    const _checkMaskRules = (data) => {
        if (!data) return null;
        return data?.startsWith('2');
    };

    const onEdit = (e) => {
        if(!e) return null;
        const { data } = e.row;
        setDataPopUpW29F2056({
            mode: "edit",
            rowData: data
        });
        setIsShowModalW29F2056(true);
    };

    const onView = (e) => {
        if(!e) return null;
        const { data } = e.row;
        setDataPopUpW29F2056({
            mode: "view",
            rowData: data
        });
        setIsShowModalW29F2056(true);
    };
    const onDelete = (mode,e) => {
        if(!e) return null;
        const { data } = e.row;
        if(mode === "add") {
          const dataFilter = dataGridEmployee.filter(item => item.EmployeeID !== data.EmployeeID);
          setDataGridEmployee(dataFilter);
        }
        if(mode === "edit") {
            if(dataGrid) {
                e.component.cellValue(e.rowIndex, "Deleted", 1);
                e.component.saveEditData();
            }
        }
    };

    const onCancel = (e) => {
        const {data} = e.row;
        const params = {
            TransID: _.get(data, "TransID", "")
        };
        Config.popup.show("YES_NO", Config.lang("Ban_co_chac_chan_muon_huy.Neu_huy_thi_se_khong_the_phuc_hoi"), () => {
            props.w29f2055Actions.cancelData(params, async (error) => {
                if (error) {
                    Config.popup.show('ERROR', error);
                    return false;
                }
                if(dataGrid) {
                    e.component.cellValue(e.rowIndex, "IsCancel", 2);
                    e.component.saveEditData();
                }
                Config.notify.show('success', Config.lang("Da_huy_thanh_cong"), 2000);
                await _saveHistory(params);

            })
        });

    };

    const _saveHistory = async (data) => {
        if(!data) return false;
        const TransID = _.get(data, "TransID", "");
        let dataSource = dataGrid.instance.option('dataSource');
        let dataTransID = dataSource.find(item => item.TransID === TransID);
        if (TransID) {
            // data Content
            const _data = {
                Name: "Huỷ tăng ca nhân viên",
                AttendanceDate: _.get(dataTransID, "AttendanceDate", ""),
                TransID: TransID,
                EmployeeID: _.get(dataTransID, "EmployeeID", ""),
                EmployeeName: _.get(dataTransID, "EmployeeName", ""),
                OTFrom: _.get(dataTransID, "OTFrom", ""),
                OTTo: _.get(dataTransID, "OTTo", ""),
            };

            //name Content
            const captions = {
                Name: "Ten",
                AttendanceDate: "Ngay_cong",
                TransID: "TransID",
                EmployeeID: "Ma_nhan_vien",
                EmployeeName: "Ten_nhan_vien",
                OTFrom: "Tang_ca_tu",
                OTTo: "Tang_ca_den",
            };

            const options = {
                data: _data,
                captions: captions,
                action: 2,
                ModuleID: "D29",
                TransID: TransID,
                TransactionID: "W29F2050",
                TransactionName: "Lập lịch tăng ca nhân viên",
            };
            const history = new History(options); //Init history
            if (await history.save()) {
                // Config.notify.show("success", Config.lang("Luu_lich_su_thanh_cong"), 2000);
            } else {
                Config.notify.show("error", Config.lang("Luu_lich_su_khong_thanh_cong"), 2000);
            }
        } else {
            Config.notify.show("error", Config.lang("Luu_lich_su_khong_thanh_cong"), 2000);
        }

    };


    const cellRenderAction = (e) => {
        if(!e) return null;
        const {data} = e.row;
        return (
            <GridActionBar>
                <div className="">
                    <IconButton
                        aria-label="view"
                        disabled={data.IsUpdate !== 0}
                        className={classes.btnStyle}
                        onClick={() => onEdit(e)}
                    >
                        <Icons name={"edit"}/>
                    </IconButton>
                    <IconButton
                        aria-label="delete"
                        disabled={data.IsDel === 1}
                        className={classes.btnStyle}
                        onClick={() => onDelete(modeW29F2050,e)}
                    >
                        <Icons name={"delete"}/>
                    </IconButton>
                    <IconButton
                        aria-label="close"
                        disabled={data?.IsCancel === 0 || data?.IsCancel === 2}
                        className={classes.btnStyle}
                        onClick={() => onCancel(e)}
                    >
                        <Icons colorHover={data?.IsCancel === 1 ? "danger" : ""} name={"cancel"} />
                    </IconButton>
                </div>
            </GridActionBar>
        );
    };

    const cellRenderEmployee = (e) => {
        if(!e) return null;
        const {data} = e;
        if (data) {
            if (data.UserPictureURL) {
                data.UserPictureURL = data.UserPictureURL.indexOf('http') < 0 ? Config.getCDNPath() + data.UserPictureURL : data.UserPictureURL;
            } else if (data.EmployeeID) {
                const user = Config.getUser({EmployeeID: data.EmployeeID});
                data.UserPictureURL = user && user.UserPictureURL ? user.UserPictureURL : ""
            }
        }
        return (
                <div className={"display_row align-center pdb5"}
                     style={{width: "100%", height: 37}}>
                    <div className={classes.divAvatar}>
                        {data && data.UserPictureURL ? (
                            <Image className={classes.imgAvatar} src={data.UserPictureURL}/>
                        ) : (
                            <InlineSVG className={classes.imgAvatar}
                                       src={require('../../../../assets/images/general/user_default.svg')}/>
                        )}
                    </div>
                    <Input
                        color={"primary"}
                        readOnly={true}
                        value={Config.helpers.getObjectValue(data, "EmployeeID", "")}
                        disableUnderline={true}
                    />
                </div>
        )
    };



    const checkRuleOTFrom = dataForm.OTFrom && _checkMaskRules(dataForm.OTFrom);
    const checkRuleOTTo = dataForm.OTTo && _checkMaskRules(dataForm.OTTo);
    const checkDisableNotData = !dataForm.AttendanceDate ||
                             ((!dataForm.OTFrom || dataForm.OTFrom.split("").includes("_")) ||
                             (!dataForm.OTTo || dataForm.OTTo.split("").includes("_")));
    const checkDisableTime = !dataForm.AttendanceDate || (modeW29F2050 === "edit" && rowData.IsUpdate && Boolean(rowData.IsUpdate));
    const checkShowCboOTType = paramOtType?.IsUseOTType;
    // const errorAttendanceDate = !!errorHelperText['AttendanceDate'] && errorHelperText['AttendanceDate'].length > 0;
    const errorOTFrom = !!errorHelperText['OTFrom'] && errorHelperText['OTFrom'].length > 0;
    const errorOTTo = !!errorHelperText['OTTo'] && errorHelperText['OTTo'].length > 0;
    return (
        <>
            {isShowModalW09F6000 && <W09F6000
                open={isShowModalW09F6000}
                dataDefaults={{isLoaded: true}}
                FormID={"W29F2050"}
                AttendanceDate={dataForm?.AttendanceDate}
                // ShiftID={dataForm?.ShiftID}
                onChosen={onSelectedEmployee}
                onClose={closeEmployeeSelect} />
            }
            {isShowModalW29F2056 && <W29F2056Popup
                open={isShowModalW29F2056}
                FormID={"W29F2056"}
                data={{
                    mode: dataPopUpW29F2056.mode,
                    rowData: dataPopUpW29F2056.rowData,
                    dataCboOtType: dataCboOtType,
                    paramOtType: paramOtType,
                    dataCaption: dataCaption
                }}
                iPermission={iPermission}
                modeW29F2050={modeW29F2050}
                onClose={(data,isLoaded,mode) => closePopupW29F2056(data,isLoaded,mode)} />
            }
            <Modal
                open={open}
                maxWidth={"lg"}
                fullWidth={true}
            >
                <Modal.Title disableTypography>
                    <ActionToolbar
                        alignment={"space-between"}
                        title={Config.lang("Lap_lich_tang_ca")}
                        showBorder={false}
                        upperCase={false}
                        className={"text-uppercase"}
                        style={{
                            marginTop: 0,
                        }}
                    >
                        <div className={"display_row align-center"}>
                            <div>
                                <ButtonGeneral
                                    name={Config.lang('Chon_nhan_vien')}
                                    size={"large"}
                                    color={"primary"}
                                    icon={<InlineSVG className={'btn-icon-svg'}
                                                     src={require('../../../../assets/images/general/people.svg')}/>}
                                    variant={"custom"}
                                    disabled={iPermission <= 1 || checkDisableNotData || !dataForm?.AttendanceDate}
                                    style={{ textTransform: 'uppercase', margin: "0px 21px", padding: "6px 12px"}}
                                    onClick={openEmployeeSelect}/>
                            </div>
                            <div>
                                <Combo
                                    margin={"dense"}
                                    style={{marginTop: 4}}
                                    dataSource={cboGroupEmployee.rows}
                                    displayExpr={"GroupName"}
                                    valueExpr={"GroupID"}
                                    stylingMode={"outlined"}
                                    showClearButton={true}
                                    disabled={checkDisableNotData || !dataForm?.AttendanceDate}
                                    // disabled={loading}
                                    // value={databaseName}
                                    label={Config.lang('Chon_nhom_nhan_vien')}
                                    onValueChanged={e => handleChange("GroupID", e)}
                                />
                            </div>
                        </div>
                        <div>
                            <IconButton aria-label="close" style={classes.closeButton} onClick={() => onClosePopup(false)}>
                                <CloseIcon/>
                            </IconButton>
                        </div>
                    </ActionToolbar>

                </Modal.Title>
                <Modal.Content>
                    <FormGroup>
                        <Row className={"wrap"} style={{ alignItems: 'baseline'}}>
                            <Col xs={12} sm={12} md={8}>
                                <DatePicker
                                    required
                                    error={errorHelperText && errorHelperText['AttendanceDate']}
                                    readOnly={isReadOnly}
                                    label={Config.lang("Ngay_tang_ca")}
                                    viewType={"underlined"}
                                    placeholder={"dd/mm/yyyy"}
                                    displayFormat={"DD/MM/YYYY"}
                                    style={{ transform: 'translateY(1px)' }}
                                    value={dataForm.AttendanceDate}
                                    onChange={e => handleChange("AttendanceDate", e)}
                                />
                            </Col>
                            <Col xs={12} sm={6} md={4}>
                                <FormControl variant={"standard"}
                                             error={!!errorHelperText}
                                             fullWidth={true}>
                                    <Row>
                                        <Col sm={6} xs={6}>
                                            <InputMask
                                                mask={'12:34'}
                                                maskChar={checkDisableTime ? "" : "_"}
                                                alwaysShowMask={true}
                                                formatChars={{
                                                    '1': '[0-9]',
                                                    '2': checkRuleOTFrom ? '[0-3]' : '[0-9]',
                                                    '3': '[0-5]',
                                                    '4': '[0-9]',
                                                }}
                                                onBlur={() => {
                                                    if (dataForm.OTFrom) {
                                                        if (dataForm.OTFrom.split('').includes("_")) {
                                                            setStateErrorText({errorMask: Config.lang('Ban_phai_nhap_dung_dinh_dang_vd_11:11')});
                                                        } else {
                                                            setStateErrorText({errorMask: ""});
                                                        }
                                                    }

                                                }}
                                                error={ !dataForm.OTFrom &&
                                                        !!errorOTFrom}
                                                disabled={checkDisableTime}
                                                helperText={!dataForm.OTFrom &
                                                            errorHelperText['OTFrom'] &&
                                                            errorHelperText['OTFrom']}
                                                value={dataForm.OTFrom || ""}
                                                onChange={(e) => handleChange("OTFrom", e)}
                                            >
                                                {(props) => {
                                                    return <TextField {...props}
                                                                       label={Config.lang("Gio_tang_ca_tu")}
                                                                       InputLabelProps={{shrink: true}}
                                                                       error={  !dataForm.OTFrom &&
                                                                                !!errorOTFrom}
                                                                       helperText={ !dataForm.OTFrom &&
                                                                                    errorHelperText['OTFrom'] && errorHelperText['OTFrom']}
                                                                      inputProps={{
                                                                          readOnly: checkDisableTime,
                                                                          id: "cursorOTFrom",
                                                                          onMouseUp: (e) => {
                                                                              if(window.getSelection()) {
                                                                                  setTypeOTFrom.current = false;
                                                                              }
                                                                          }
                                                                      }}
                                                                       required={true}
                                                                       variant={"standard"}/>}
                                                }
                                            </InputMask>
                                        </Col>
                                        <Col sm={6} xs={6}>
                                            <InputMask
                                                mask={'12:34'}
                                                maskChar={checkDisableTime ? "" : "_"}
                                                alwaysShowMask={true}
                                                formatChars={{
                                                    '1': '[0-9]',
                                                    '2': checkRuleOTTo ? '[0-3]' : '[0-9]',
                                                    '3': '[0-5]',
                                                    '4': '[0-9]',
                                                }}
                                                error={ !dataForm.OTTo &&
                                                        !!errorOTTo}
                                                disabled={checkDisableTime}
                                                helperText={!dataForm.OTTo &&
                                                            errorHelperText['OTTo'] &&
                                                            errorHelperText['OTTo']}
                                                onBlur={() => {
                                                    if (dataForm.OTTo) {
                                                        if (dataForm.OTTo.split('').includes("_")) {
                                                            setStateErrorText({errorMask: Config.lang('Ban_phai_nhap_dung_dinh_dang_vd_11:11')});
                                                        } else {
                                                            setStateErrorText({errorMask: ""});
                                                        }
                                                    }

                                                }}
                                                value={dataForm.OTTo || ""}
                                                onChange={(e) => handleChange("OTTo", e)}
                                            >
                                                {(props) => <TextField {...props}

                                                                       label={Config.lang("Gio_tang_ca_den")}
                                                                       InputLabelProps={{shrink: true}}
                                                                       error={ !dataForm.OTTo && !!errorOTTo}
                                                                       helperText={!dataForm.OTTo &&
                                                                                   errorHelperText['OTTo'] &&
                                                                                   errorHelperText['OTTo']}
                                                                       inputProps={{
                                                                           readOnly: checkDisableTime,
                                                                           id: "cursorOTTo",
                                                                           onMouseUp: (e) => {
                                                                               if(window.getSelection()) {
                                                                                   setTypeOTTo.current = false;
                                                                               }
                                                                           }
                                                                       }}
                                                                       required={true}
                                                                       variant={"standard"}/>}
                                            </InputMask>
                                        </Col>
                                    </Row>
                                    <FormHelperText style={{whiteSpace: "nowrap", position: "absolute", textAlign: "center", width: "100%", bottom: "-30px"}}>
                                        {(errorHelperText && errorHelperText["errorMask"]) || (errorHelperText && errorHelperText["errorTime"])}
                                    </FormHelperText>
                                </FormControl>
                            </Col>
                        </Row>
                        {!!checkShowCboOTType &&
                        <Row>
                            <Col xs={12} sm={12} md={8}>

                                    <Combo
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        margin={"normal"}
                                        stylingMode={"underlined"}
                                        label={Config.lang("Loai_tach_tang_ca")}
                                        showClearButton={true}
                                        dataSource={dataCboOtType}
                                        displayExpr={"OTTypeName"}
                                        valueExpr={"OTTypeID"}
                                        className={classes.cboUnderline}
                                        onValueChanged={e => handleChange("OTTypeID", e)}
                                        value={dataForm.OTTypeID}
                                        selectProps={{
                                            readOnly: checkDisableNotData
                                        }}
                                    />
                            </Col>
                        </Row>
                        }

                        <Row>
                            {
                                dataCaption.map((item,indx) => {
                                    if(!item.Disabled) return null;
                                    return (
                                        <FormControlLabel key={indx}
                                                          control={
                                                              <Checkbox
                                                                  checked={Boolean(dataCheckboxCaption[item.RefID])}
                                                                  onChange={(e) => handleCheckbox(item.RefID,e.target.checked)}
                                                                  name={item.RefCaption}
                                                              />
                                                          }
                                                          label={item.RefCaption}
                                        />
                                    )
                                })
                            }
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <TextField
                                    label={Config.lang("Ly_do_tang_ca")}
                                    variant={"standard"}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    margin={"normal"}
                                    value={dataForm.ReasonOT}
                                    // disabled={loadingForm}
                                    onChange={(e) => handleChange("ReasonOT", e)}
                                    fullWidth
                                />
                            </Col>
                            <Col xs={12}>
                                <TextField
                                    label={Config.lang("Ghi_chu")}
                                    variant={"standard"}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    margin={"normal"}
                                    value={dataForm.Note}
                                    // disabled={loadingForm}
                                    onChange={(e) => handleChange("Note", e)}
                                    fullWidth
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                            <ExpansionPanel
                                expanded={expandedFirst}
                                className={classes.noneBorder}
                                style={{ boxShadow: "none" }}
                                onChange={() => setExpandedFirst(!expandedFirst)}
                            >
                                <div className={"display_row align-center"}>
                                    <ExpansionPanelSummary
                                        className={classes.ExpansionPanelSummary}
                                    >
                                        <i
                                            className={"fa display_row align-center " + (expandedFirst ? "fa-angle-down" : "fa-angle-right")}
                                            style={{ fontSize: 20, width: 20 }}
                                        />
                                        <Typography className={"display_row align-center"}>{Config.lang("Danh_sach_nhan_vien")}</Typography>
                                    </ExpansionPanelSummary>
                                    <ButtonGeneral
                                        name={Config.lang("Cap_nhat")}
                                        size={"small"}
                                        className={"mgl5"}
                                        disabled={checkDisableNotData}
                                        variant={"outlined"}
                                        onClick={() => {
                                            setExpandedFirst(true);
                                            _onUpdate();
                                        }}
                                    />

                                </div>

                                <ExpansionPanelDetails style={{ padding: 0,width: "100%" }}>
                                    <Col xs={12}>
                                        <GridContainer
                                            reference={ref => dataGrid = ref}
                                            dataSource={dataGridEmployee}
                                            keyExpr={"TransID"}
                                            // style={{ border: "none" }}
                                            columnAutoWidth={true}
                                            onDbCellClick={onView}
                                            showRowLines={true}
                                            showBorders={false}
                                            // filterPanel={{visible: true}}
                                            filterValue={["Deleted", "=", 0]}
                                            showColumnLines={false}
                                            noDataText={Config.lang("No_data")}
                                            hoverStateEnabled={true}
                                            rowAlternationEnabled={false}
                                            style={{border: "none", overflowX: "scroll"}}
                                            typePaging={"normal"}
                                            pagerFullScreen={false}
                                            onRowPrepared={(e) => {
                                                if(!e) return false;
                                                const {data} = e;
                                                if (data?.IsCancel === 2){
                                                    e.rowElement.classList.add(classes.bgRow);
                                                }
                                            }}
                                        >
                                            <Column
                                                caption={Config.lang("Hanh_dong")}
                                                alignment={"center"}
                                                visible={Config.isMobile}
                                                allowEditing={false}
                                                cellRender={cellRenderAction}
                                            />
                                            <Column
                                                fixed={true}
                                                caption={Config.lang("Ma_nhan_vien")}
                                                cellRender={cellRenderEmployee}
                                                allowEditing={false}
                                                minWidth={250}
                                            />
                                            <Column
                                                caption={Config.lang("Ten_nhan_vien")}
                                                dataField={'EmployeeName'}
                                                allowEditing={false}
                                                fixed={true}
                                                minWidth={180}
                                            />
                                            <Column
                                                caption={Config.lang("Ngay_di_ca")}
                                                minWidth={120}
                                                dataType={"date"}
                                                dataField={'AttendanceDate'}
                                                allowEditing={false}
                                            />
                                            <Column
                                                caption={Config.lang('Ca')}
                                                dataField={"ShiftID"}
                                                allowEditing={false}
                                                minWidth={90}
                                            />
                                            <Column
                                                caption={Config.lang("Ca_vao_ra")}
                                                dataField={'ShiftInOut'}
                                                allowEditing={false}
                                                minWidth={90}
                                            />
                                            <Column
                                                visible={false}
                                                dataField={'IsOverTime'}
                                                allowEditing={false}
                                            />
                                            <Column
                                                visible={false}
                                                dataField={'IsUpdate'}
                                                allowEditing={false}
                                            />
                                            <Column
                                                visible={false}
                                                dataField={'IsCancel'}
                                                allowEditing={false}
                                            />
                                            <Column
                                                visible={false}
                                                dataField={'Deleted'}
                                                allowEditing={false}
                                            />
                                            <Column
                                                visible={false}
                                                dataField={'OTHour'}
                                                allowEditing={false}
                                            />
                                            <Column
                                                caption={Config.lang("Tang_ca_tu")}
                                                dataField={'OTFrom'}
                                                allowEditing={false}
                                                minWidth={90}
                                            />
                                            <Column
                                                caption={Config.lang("Tang_ca_den")}
                                                dataField={'OTTo'}
                                                allowEditing={false}
                                                minWidth={90}
                                            />
                                            {dataCaption && dataCaption.map((item,indx) => {
                                                if(!item.Disabled) return null;
                                                return (
                                                    <Column
                                                        key={indx}
                                                        caption={item.RefCaption}
                                                        dataField={item.RefID}
                                                        alignment={"center"}
                                                        dataType={"boolean"}
                                                        allowEditing={false}
                                                        minWidth={90}
                                                    />
                                                )
                                            })}
                                            <Column
                                                caption={Config.lang("Loai_tach_tang_ca")}
                                                dataField={'OTTypeID'}
                                                lookup={{
                                                    dataSource: dataCboOtType,
                                                    valueExpr: "OTTypeID",
                                                    displayExpr: "OTTypeName",
                                                }}
                                                allowEditing={false}
                                                minWidth={90}
                                            />
                                            <Column
                                                caption={Config.lang("Ghi_chu")}
                                                dataField={'Note'}
                                                allowEditing={false}
                                                minWidth={90}
                                            />
                                            <Column
                                                alignment={"right"}
                                                fixed={true}
                                                fixedPosition={"right"}
                                                width={110}
                                                visible={!Config.isMobile}
                                                cellRender={cellRenderAction}
                                            />
                                        </GridContainer>
                                    </Col>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                    </FormGroup>
                    <FormGroup style={{marginBottom: 34}}>
                        <Attachments
                            ref={attRef}
                            showButton={true}
                            files={dataOldAttachments}
                            disabled={ uploading || iPermission <= 1}
                            maxLength={5}
                            uploading={uploading}
                            onUploading={onUploading}
                            onChanged={onChangeAttachments}
                        />
                    </FormGroup>
                </Modal.Content>
                <Modal.Actions style={{ justifyContent: "space-between" }}>
                    <div>
                        <IconButton aria-label="view" disabled={true} onClick={onAttachment}>
                            <AttachFileIcon/>
                        </IconButton>
                    </div>
                    <div>
                        <ButtonGeneral
                            name={Config.lang("Luu")}
                            typeButton={"save"}
                            disabled={isModeView || iPermission <= 1 || (modeW29F2050 === "edit" && iPermission <= 2)}
                            style={{textTransform: "uppercase"}}
                            size={"large"}
                            onClick={onSave}
                        />
                    </div>

                </Modal.Actions>
            </Modal>

        </>
    );
};

export default compose(
    connect(null,
        (dispatch) => ({
            w29f2055Actions: bindActionCreators(W29F2055Actions, dispatch),
            generalActions: bindActionCreators(generalActions, dispatch),
        })
    ),
)(W29F2055Popup);
