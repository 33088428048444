import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";

import W29F2061 from "../W29F2061/W29F2061";
import LisApproval from "../../../common/list-approval";
import Config from "../../../../config";

import * as generalActions from "../../../../redux/general/general_actions";
import * as W29F2060Actions from "../../../../redux/W2X/W29F2060/W29F2060_actions";

import { Col, DateRangePicker, Dropdown, Typography } from "diginet-core-ui/components";
import { useColor } from "diginet-core-ui/theme";

const W29F2060 = ({ classes, location }) => {
    const [dataGrid, setDataGrid] = useState({ rows: [], total: 0 });
    const [dataCboShift, setDataCboShift] = useState([]);
    const [dataCboAppStatus, setDataCboAppStatus] = useState([]);
    const [iPermission, setIPermission] = useState(0);

    const [isLoadingCboShift, setLoadingCboShift] = useState(false);
    const [isShowModal, setShowModal] = useState(null);

    const listApproval = useRef();
    const dispatch = useDispatch();

    useEffect(() => {
        Config.callChildForm(
            {
                FormID: "W29F2060",
                // ID: voucher_id,
                keyExpr: "TransID",
                data: dataGrid?.rows,
                onLoad: params => {
                    if (listApproval.current) listApproval.current.loadGrid({ TransID: params?.ID });
                },
                onAction: (e, data) => onOpenModal("view", data),
            },
            { location }
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    });

    const loadForm = (param, cb) => {
        const params = {
            DivisionID: Config.getDivisionID(),
            ...param,
            ShiftID: param?.ShiftID ?? "",
            AppStatusID: param?.AppStatusID ?? null,
            TransID: param?.TransID ?? "",
            DateFrom: param?.DateFrom ?? null,
            DateTo: param?.DateTo ?? null,
        };
        return W29F2060Actions.loadForm(params, (err, data) => {
            cb && cb(err, data);
            setDataGrid(data);
        });
    };

    const loadCboShift = () => {
        setLoadingCboShift(true);
        dispatch(
            W29F2060Actions.loadShift({}, (err, data) => {
                setLoadingCboShift(false);
                if (err) Config.popup.show("ERROR", err);
                setDataCboShift(data || []);
            })
        );
        return true;
    };

    const onLoadCboFilter = () => {
        loadCboShift();
    };

    const onOpenModal = (mode = "add", data = {}) => {
        setShowModal({
            data: data,
            mode: mode,
        });
        loadCboShift();
    };

    const onCloseModal = (isReload = false) => {
        if (isReload) listApproval.current.loadGrid();
        setShowModal(null);
    };

    const renderFilter = (dataFilter, filterChange) => {
        return (
            <>
                <Col xs={12}>
                    <DateRangePicker
                        viewType={"outlined"}
                        label={Config.lang("Ngay")}
                        onChange={e => filterChange("Date", e, "Date")}
                        clearAble
                        controls
                        placeholder={"DD/MM/YYYY"}
                        defaultValue={[dataFilter.DateFrom, dataFilter.DateTo]}
                    />
                </Col>
                <Col xs={12}>
                    <Dropdown
                        label={Config.lang("Ca")}
                        viewType={"outlined"}
                        dataSource={dataCboShift}
                        displayExpr={"ShiftName"}
                        valueExpr={"ShiftID"}
                        loading={isLoadingCboShift}
                        onChange={e => filterChange("ShiftID", e)}
                        clearAble
                        defaultValue={dataFilter.ShiftID}
                    />
                </Col>
                <Col xs={12}>
                    <Dropdown
                        clearAble
                        label={Config.lang("Trang_thai_duyet")}
                        viewType={"outlined"}
                        dataSource={dataCboAppStatus}
                        displayExpr={"AppStatusName"}
                        valueExpr={"AppStatusID"}
                        onChange={e => filterChange("AppStatusID", e)}
                        showClearButton={true}
                        defaultValue={dataFilter.AppStatusID}
                    />
                </Col>
            </>
        );
    };

    const renderInfo = ({ data }) => {
        const dataField = [
            {
                label: Config.lang("Ngay"),
                value: Config.convertDate(data?.AttendanceDate, "", "DD/MM/YYYY"),
                separator: " ",
                color: useColor?.system?.active,
            },
            {
                label: data?.ShiftID,
                value: data?.ShiftName,
                separator: " ",
            },
            {
                label: Config.lang("Gio_tang_ca"),
                value: data?.OTHour,
                separator: " ",
            },
            {
                label: Config.lang("Ly_do"),
                value: data?.ReasonOT,
                separator: ": ",
            },
            {
                label: Config.lang("Ghi_chu_duyet"),
                value: data?.ApprovalNote,
                separator: ": ",
            },
            {
                label: Config.lang("Nguoi_duyet"),
                value: data?.ApproverName,
                separator: ": ",
            },
        ].filter(item => !!item.value);
        return (
            <div>
                {dataField.map((item, i) => (
                    <Typography
                        key={i}
                        type={"h3"}
                        color={item.color}
                        className={"mgr4x"}
                        style={{ display: i <= 1 && "inline-flex" }}
                    >
                        {`${item.label || ""}${item.separator || ""} ${item.value || ""}`}
                    </Typography>
                ))}
            </div>
        );
    };

    const onPrint = data => {
        dispatch(
            W29F2060Actions.exportLeaveOfAbsenceForm({ TransID: data.TransID }, (err, dataPrint) => {
                if (err) Config.popup.show("ERROR", err);
                else if (dataPrint?.URL) {
                    const link = document.createElement("a");
                    link.href = dataPrint.URL;
                    link.download = dataPrint.fileName;
                    link.click();
                }
            })
        );
    };

    return (
        <>
            {isShowModal && (
                <W29F2061
                    isShowModal={!!isShowModal}
                    iPermission={iPermission}
                    modeForm={isShowModal.mode}
                    data={isShowModal.data}
                    onClose={onCloseModal}
                    dataCboShift={dataCboShift}
                />
            )}
            <LisApproval
                ref={listApproval}
                FormID={"W29F2060"}
                keyExpr={"TransID"}
                title={Config.lang("Dang_ky_tang_ca_ho")}
                onAdd={{ action: () => onOpenModal("add"), disabled: iPermission <= 2 }}
                action={{
                    loadGrid: loadForm,
                    loadStatus: (params, cb) =>
                        generalActions.getCboAppStatus({ ...params, FormID: "STANDARD" }, (err, data) => {
                            setDataCboAppStatus(data || []);
                            cb && cb(err, data);
                        }),
                }}
                isLoadPermission
                filterProps={{
                    onOpenLoaded: onLoadCboFilter,
                }}
                col={["80%", 0]}
                renderEmployee={renderInfo}
                renderInfo={false}
                renderAction={e => {
                    const { data = {} } = e?.row || {};
                    return {
                        History: true,
                        View: {
                            disabled: iPermission <= 0,
                            action: () => onOpenModal(data.AppStatusID === 0 ? "edit" :"view", data),
                        },
                        Delete: {
                            disabled: iPermission <= 3 || data.IsUpdate !== 0,
                            action: W29F2060Actions.deleteRow,
                        },
                        PaperFilled: {
                            disabled: data.IsExport !== 1,
                            action: () => onPrint(data),
                            tooltip: Config.lang("Xuat_don_tu")
                        },
                    };
                }}
                renderFilter={renderFilter}
                onChangePermission={setIPermission}
                onDbCellClick={({ data }) =>
                    iPermission > 1
                        ? onOpenModal(iPermission > 2 && data?.AppStatusID === 0 ? "edit" : "view", data)
                        : false
                }
            />
        </>
    );
};

export default W29F2060;
