/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 1/28/2021
 * @Example
 */

import React, {useState, useEffect, useRef}                         from "react";
import { compose, bindActionCreators }           from "redux";
import { connect }                               from "react-redux";
import {Col, Row, FormGroup} from "react-bootstrap";
import {
    makeStyles,
    FormControl, FormHelperText,
} from "@material-ui/core";
import Config                                    from "../../../../config";
import {Combo}                            from "../../../common/form-material";
import ButtonGeneral                             from "../../../common/button/button-general";
import { FormControlLabel, Checkbox, TextField } from "@material-ui/core";
import * as W29F2056Actions                      from "../../../../redux/W2X/W29F2056/W29F2056_actions";
import * as generalActions                       from "../../../../redux/general/general_actions";
import DateBoxPicker from "../../../common/form-material/date-box";
import InputMask from "react-input-mask";
import _ from "lodash";
import LabelText from "../../../common/label-text/label-text";
import moment from "moment";
import History from "../../../libs/history";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "diginet-core-ui/components";
const useStyles = makeStyles(() => ({
    rmUnderLine: {
        "& .MuiInput-underline:before": {
            content: "none",
        },
        "& .MuiInput-underline:after": {
            content: "none",
        },
    },
}));

const W29F2056 = (props) => {
    const classes = useStyles();

    const _initDataForm = {
        TransID: "",
        ShiftID: "",
        EmployeeID: null,
        AttendanceDate: null,
        OTFrom: "",
        OTTo: "",
        OTTypeID: "",
        RefID: "",
        ReasonOT: "",
        Note: "",
        OTHour: "",
        IsOverTime: 0,
    };

    const _initDataCheckboxCaption = {
        IsConfirm01: 0,
        IsConfirm02: 0,
        IsConfirm03: 0,
        IsConfirm04: 0,
        IsConfirm05: 0
    };

    const {data, open, iPermission} = props;
    const { mode, rowData, dataCboOtType, paramOtType, dataCaption } = data || {};
    const [errorHelperText, setErrorHelperText] = useState({});
    const [isReadOnly, setIsReadOnly] = useState(false);
    const [dataForm, setDataForm] = useState(_initDataForm);
    const [dataOtHour, setDataOtHour] = useState({});
    const [dataCheckboxCaption, setCheckboxCaption] = useState(_initDataCheckboxCaption);
    const [isDisabled, setIsDisabled] = useState(false);
    const isChange = useRef(false);
    const dataOldForm = useRef({});
    const isSetOtHour = useRef(false);
    let setCursorOTFrom = useRef(false);
    let setCursorOTTo = useRef(false);
    let setTypeOTFrom = useRef(false);
    let setTypeOTTo = useRef(false);
    let onTab = useRef(false);
    let onBlurOTTo = useRef(false);
    let onBlurOTFrom = useRef(false);

    const loadOtHour = () => {
        const params = {
            AttendanceDate: dataForm && dataForm.AttendanceDate ? moment(dataForm.AttendanceDate).format("YYYY-MM-DD") : null,
            OTFrom: dataForm.OTFrom ? dataForm.OTFrom.replace(":","") : "",
            OTTo: dataForm.OTTo ? dataForm.OTTo.replace(":","") : "",
            EmployeeID: dataForm?.EmployeeID || null,
            ShiftID: dataForm?.ShiftID || "VP01",
            Language: Config.language || 84,
            Mode: 2,
        };

        props.w29f2056Actions.getOtHour(params, (error, data) => {
            if (error) {
                Config.popup.show('ERROR', error);
                return false;
            }
            if(data) {
                if(data.Status === 1) {
                    let message = data.Message || Config.lang("Loi_chua_xac_dinh");
                    Config.popup.show('INFO', message);
                    setData({OTTo: ""});
                    setTypeOTTo.current = false;
                }
                if(data.Status === 0) {
                    setDataOtHour(data);
                }
            }
        });
    };

    useEffect(() => {
        if(mode === "edit") {
            setIsReadOnly(true);
            setData({
                EmployeeID: rowData.EmployeeID,
                OTTypeID: rowData.OTTypeID,
                TransID: rowData.TransID,
                AttendanceDate: rowData.AttendanceDate,
                ShiftID: rowData.ShiftID,
                OTFrom: rowData.OTFrom,
                OTTo: rowData.OTTo,
                Note: rowData.Note,
                OTHour: rowData.OTHour,
                IsOverTime: rowData.IsOverTime,
            });
            setCheckboxCaption({
                IsConfirm01: rowData.IsConfirm01,
                IsConfirm02: rowData.IsConfirm02,
                IsConfirm03: rowData.IsConfirm03,
                IsConfirm04: rowData.IsConfirm04,
                IsConfirm05: rowData.IsConfirm05});
            dataOldForm.current = {
                EmployeeID: rowData.EmployeeID,
                EmployeeName: rowData.EmployeeName,
                OTTypeID: rowData.OTTypeID,
                OTTypeName: rowData.OTTypeName,
                TransID: rowData.TransID,
                AttendanceDate: rowData.AttendanceDate,
                ShiftID: rowData.ShiftID,
                ShiftInOut: rowData.ShiftInOut,
                OTFrom: rowData.OTFrom,
                OTTo: rowData.OTTo,
                Note: rowData.Note,
                OTHour: rowData.OTHour,
                IsConfirm01: Number(rowData.IsConfirm01),
                IsConfirm02: Number(rowData.IsConfirm02),
                IsConfirm03: Number(rowData.IsConfirm03),
                IsConfirm04: Number(rowData.IsConfirm04),
                IsConfirm05: Number(rowData.IsConfirm05),
                IsOverTime: rowData.IsOverTime,
            }
        }
        if(mode === "view") {
            setIsDisabled(true);
            setData({
                EmployeeID: rowData.EmployeeID,
                OTTypeID: rowData.OTTypeID,
                TransID: rowData.TransID,
                AttendanceDate: rowData.AttendanceDate,
                ShiftID: rowData.ShiftID,
                OTFrom: rowData.OTFrom,
                OTTo: rowData.OTTo,
                Note: rowData.Note,
                OTHour: rowData.OTHour,

            });
            setCheckboxCaption({
                IsConfirm01: rowData.IsConfirm01,
                IsConfirm02: rowData.IsConfirm02,
                IsConfirm03: rowData.IsConfirm03,
                IsConfirm04: rowData.IsConfirm04,
                IsConfirm05: rowData.IsConfirm05})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[data?.mode]);

    useEffect(() => {
        if(dataForm.AttendanceDate &&
            dataForm.OTFrom &&
            dataForm.OTTo &&
            dataForm.OTFrom !== "" &&
            dataForm.OTTo !== "" &&
            !dataForm.OTFrom.includes("_") &&
            !dataForm.OTTo.includes("_")
        ) {
            if(isChange.current) {
                // loadOtHour();
                isChange.current = false;
            }
        }

        checkOtFromEmpty(dataForm.OTFrom);
        checkOttoEmpty(dataForm.OTTo);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[dataForm.OTFrom,dataForm.OTTo]);


    const checkChangeTime = (data) => {
        let test = data.split('');
        return test.every((a, i) => {
            if (i === 0) {
                return !isNaN(a);
            }
            return isNaN(a);
        })
    };

    const CheckDataWhenSelection = (data) => {
        let test = data.split('');
        return test.every((a, i) => {
            if (i === 2) {
                return typeof (a) === 'string'
            }
            return !isNaN(a);

        })};

    const moveCursor = (id, data, cb) => {
        let check = false;
        if(data.charAt(0) <= 2 && data.charAt(1) <= 2 && CheckDataWhenSelection(data)) {
            check = true;
        }
        if(id === "popupCursorOTFrom") {
            setCursorOTFrom.current = true;
        }
        if(id === "popupCursorOTTo") {
            setCursorOTTo.current = true;
        }
        let el = document.getElementById(id);
        el.setSelectionRange(check ? 1 : 3,check ? 1 : 3);
        cb && cb();
    };

    const checkOtFromEmpty = (data) => {
        if(!data) return false;
        if(data.includes("_")) {
            const arr = data.split('');
            const dataFilter = arr.filter(item => item === "_");
            if(dataFilter.length >= 4) {
                setCursorOTFrom.current = false;
                setTypeOTFrom.current = false;
            }
        }
    };

    const checkOttoEmpty = (data) => {
        if(!data) return false;
        if(data.includes("_")) {
            const arr = data.split('');
            const dataFilter = arr.filter(item => item === "_");
            if(dataFilter.length >= 4) {
                setCursorOTTo.current = false;
                setTypeOTTo.current = false;
            }
        }
    };

    useEffect(() => {
        if(!_.isEmpty(dataOtHour)) {
            isSetOtHour.current = true;
        }
    },[dataOtHour]);

    useEffect(() => {
        if(!_.isEmpty(dataOtHour)) {
            isSetOtHour.current = true;
        }
    },[dataOtHour]);

    const onClosePopup = (data,isReload,mode) => {
        const { onClose } = props;
        if(onClose) onClose(data,isReload,mode);
    };

    const setStateErrorText = (objValue) => {
        if(objValue) {
            setErrorHelperText({
                ...errorHelperText,
                ...objValue
            });
            return Object.keys(objValue).length !== 0;
        } else {
            setErrorHelperText({});
            return false;
        }
    };

    const handleCheckbox = (key,value) => {
        setCheckboxCaption({...dataCheckboxCaption,[key]:Number(value)})
    };

    const setData = (obj) => {
        setDataForm({...dataForm,...obj});
    };

    const handleChange = async (key,e) => {
        if(!key) return false;
        isChange.current = true;
        const value = _.get(e, 'value', _.get(e, 'target.value', ''));
        const selection = window.getSelection();
        if (key === "OTFrom") {
            if (!value.split("").includes("_")) {
                setStateErrorText({errorMask: ""});
            }
        }
        if (key === "OTTo") {
            if (!value.split("").includes("_")) {
                setStateErrorText({errorMask: ""});
            }
        }
        switch (key) {
            case "AttendanceDate":
                setData({AttendanceDate: value});
                break;
            case "OTFrom":
                if(value.charAt(0) > 2 && CheckDataWhenSelection(value)) {
                    return;
                }
                if(selection && !setTypeOTFrom.current && checkChangeTime(value)) {
                    if(selection.focusNode.childNodes[0].id === "popupCursorOTFrom") {
                        setCursorOTFrom.current = false;
                        await setDataTime("OTFrom", value);

                    }
                } else {
                    setData({OTFrom:  value});
                    setTypeOTFrom.current = true;
                }
                break;
            case "OTTo":
                if(value.charAt(0) > 2 && CheckDataWhenSelection(value)) {
                    return;
                }
                if(selection && !setTypeOTTo.current && checkChangeTime(value)) {
                    if(selection.focusNode.childNodes[0].id === "popupCursorOTTo") {
                        setCursorOTTo.current = false
                        await setDataTime("OTTo", value);
                    }
                } else {
                    setData({OTTo: value});
                    setTypeOTTo.current = true;
                }
                break;
            case "OTTypeID":
                setData({OTTypeID: value});
                break;
            case "Note":
                setData({Note: value});
                break;
            default:
                break;
        }
    };

    const swap = (arr,x,y) => {
        let tmp = arr[x];
        arr[x] = arr[y];
        arr[y] = tmp;
        return arr;
    };

    const setDataTime = (key,data) => {
        if(!key || !data) return false;
        let arr = data.split("");
        if(arr && arr.length > 0) {
            if(Number(arr[0]) >= 3){
                arr = swap(arr,0,1);
                for(let i = 0; i < arr.length; i++){
                    arr[i] = arr[i] === "_" ? "0" : arr[i];
                }
            } else {
                for(let i = 0; i < arr.length; i++){
                    arr[i] = arr[i] === "_" ? "0" : arr[i];
                }
            }
        }
        if(key === "OTFrom") {
            setData({OTFrom: arr.join('')});
            if(!setCursorOTFrom.current) {
                setTimeout(() => {
                    moveCursor('popupCursorOTFrom', arr.join(''));
                }, 100)
            }
        }
        if(key === "OTTo") {
            setData({OTTo: arr.join('')});
            if(!setCursorOTTo.current) {
                setTimeout(() => {
                    moveCursor('popupCursorOTTo', arr.join(''));
                }, 100)
            }
        }
    };

    const convertBooleanToNumber = (data) => {
        if(!data) return false;
        const dataConvert = {};
        if(_.isObject(data)) {
            for (let [key, value] of Object.entries(data)) {
                if(_.isBoolean(value)) {
                    value = Number(value);
                }
                dataConvert[key] = value;
            }
        }
        return dataConvert;
    };


    const onSave = () => {
        if(_.isEmpty(dataForm.AttendanceDate)||
            _.isEmpty(dataForm.OTFrom) ||
            (dataForm.OTFrom && dataForm.OTFrom.split("").includes("_")) ||
            (dataForm.OTTo && dataForm.OTTo.split("").includes("_")) ||
            _.isEmpty(dataForm.OTTo)) {
            Config.popup.show('INFO', Config.lang("Khong_duoc_phep_luu"));
            return false;
        }
        const dataChecked = convertBooleanToNumber(dataCheckboxCaption) || 0;
        const params = {
            TransID: dataForm?.TransID || "",
            AttendanceDate: dataForm && dataForm.AttendanceDate ? moment(dataForm.AttendanceDate).format("YYYY-MM-DD") : "",
            OTFrom: dataForm && dataForm.OTFrom ? dataForm.OTFrom.replace(":", "") : "",
            OTTo: dataForm && dataForm.OTTo ? dataForm.OTTo.replace(":", "") : "",
            OTTypeID: dataForm?.OTTypeID || "",
            Note: dataForm?.Note || "",
            IsOverTime: (dataOtHour?.IsOverTime || dataOtHour?.IsOverTime === 0) ? dataOtHour?.IsOverTime :
                (dataForm?.IsOverTime ||  dataForm?.IsOverTime === 0) ? dataForm?.IsOverTime : 0,
            OTHour: ((isSetOtHour.current) ? (dataOtHour?.OTHour || 0 ) : (dataForm?.OTHour || 0)),
            ...dataChecked
        };

        props.w29f2056Actions.saveData(params, async (error,data) => {
            if (error) {
                Config.popup.show('ERROR', error);
                return false;
            }
            if(data) {
                Config.notify.show('success', Config.lang("Luu_thanh_cong"), 2000);
                await saveHistory(params);
                let mode = "";
                if(params.TransID === "") {
                    mode = "add";
                } else {
                    mode = "edit";
                }
                if (mode === "edit") await saveHistory(params);
                onClosePopup({EmployeeID:rowData.EmployeeID,...params}, true, mode);
            }
        });
    };

    const saveHistory = async (data) => {
        if(!data) return false;
        const TransID = _.get(data, "TransID", "");
        let OTTypeID =_.get(data, "OTTypeID", "");
        let OtTypeName = dataCboOtType.find(item => item.OTTypeID === OTTypeID);
        if (TransID) {
            // data Content
            const _data = {
                EmployeeID: _.get(dataForm, "EmployeeID", ""),
                EmployeeName: _.get(rowData, "EmployeeName", ""),
                AttendanceDate: _.get(dataForm, "AttendanceDate", ""),
                ShiftInOut: _.get(rowData, "ShiftInOut", ""),
                ShiftID: _.get(dataForm, "ShiftID", ""),
                OTFrom: _.get(dataForm, "OTFrom", ""),
                OTTo: _.get(dataForm, "OTTo", ""),
                OTTypeName: _.get(OtTypeName, "OTTypeName", ""),
                OTTypeID: OTTypeID,
                Note: _.get(data, "Note", ""),
                IsConfirm01: Number(_.get(data, "IsConfirm01", 0)),
                IsConfirm02: Number(_.get(data, "IsConfirm02", 0)),
                IsConfirm03: Number(_.get(data, "IsConfirm03", 0)),
                IsConfirm04: Number(_.get(data, "IsConfirm04", 0)),
                IsConfirm05: Number(_.get(data, "IsConfirm05", 0)),
                IsOverTime: Number(_.get(data, "IsOverTime", 0)),
            };

            //name Content
            const captions = {
                EmployeeID: "Ma_nhan_vien",
                EmployeeName: "Ten_nhan_vien",
                AttendanceDate: "Ngay_tang_ca",
                ShiftInOut: "Ca_vao_ra",
                ShiftID: "data_ShiftID",
                OTFrom: "Tang_ca_tu",
                OTTo: "Tang_ca_den",
                OTTypeName: "Loai_tach_tang_ca",
                OTTypeID: "data_OTTypeID",
                Note: "Ghi_chu",
                IsConfirm01: "data_IsConfirm01",
                IsConfirm02: "data_IsConfirm02",
                IsConfirm03: "data_IsConfirm03",
                IsConfirm04: "data_IsConfirm04",
                IsConfirm05: "data_IsConfirm05",
                IsOverTime: "data_IsOverTime",
            };
            const _dataOld = dataOldForm.current;

            const options = {
                data: _data,
                captions: captions,
                dataCompare: _dataOld,
                action: 1,
                ModuleID: "D29",
                TransID: TransID,
                TransactionID: "W29F2056",
                TransactionName: "Lập lịch tăng ca nhân viên",
            };
            const history = new History(options); //Init history
            await history.save();
        } else {
            Config.notify.show("error", Config.lang("Luu_lich_su_khong_thanh_cong"), 2000);
        }

    };

    const _checkMaskRules = (data) => {
        if (!data) return null;
        return data?.startsWith('2');
    };

    const loadApiCheckTime = async (type) => {
        let isLoad = false;
        if(type === "onTab") {
            onTab.current = true;
        }
        if(type === "onBlurOTTo") {
            onBlurOTTo.current = true;
        }
        if(type === "onBlurOTFrom") {
            onBlurOTFrom.current = true;
        }
        if(onBlurOTTo.current && onBlurOTFrom.current && onTab.current) {
            isLoad = true;
            onTab.current = false;
            onBlurOTTo.current = false;
            onBlurOTFrom.current = false;
        }
        if(dataForm.OTFrom && !isLoad && !dataForm.OTFrom.includes("_") && dataForm.OTTo && !dataForm.OTTo.includes("_")) {
            await loadOtHour();
        }
    };

    const checkRuleOTFrom = dataForm.OTFrom && _checkMaskRules(dataForm.OTFrom);
    const checkRuleOTTo = dataForm.OTTo && _checkMaskRules(dataForm.OTTo);
    const checkCboOTTypeID = !dataForm.AttendanceDate ||
        ((!dataForm.OTFrom || dataForm.OTFrom.split("").includes("_")) ||
            (!dataForm.OTTo || dataForm.OTTo.split("").includes("_")));
    return (
        <>
            <Modal
                moveable={false}
                dragAnyWhere={false}
                moveOutScreen={false}
                open={open}
                onClose={onClosePopup}
            >
                <ModalHeader title={Config.lang("Chinh_sua_thong_tin_tang_ca")}  />
                <ModalBody>
                    <FormGroup>
                        <Row>
                            <Col xs={6}>
                                <LabelText
                                    allowPadding={true}
                                    label={Config.lang("Ma_nhan_vien")}
                                    fullWidth={true}
                                    value={Config.helpers.getObjectValue(rowData, "EmployeeID", "")}
                                />
                            </Col>
                            <Col xs={6}>
                                <LabelText
                                    allowPadding={true}
                                    label={Config.lang("Ten_Nhan_vien")}
                                    fullWidth={true}
                                    value={Config.helpers.getObjectValue(rowData, "EmployeeName", "")}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={6}>
                                <DateBoxPicker
                                    placeholder={"DD/MM/YYYY"}
                                    useMaskBehavior={true}
                                    showClearButton={true}
                                    dateBoxProps={{
                                        readOnly: isReadOnly
                                    }}
                                    shrink={true}
                                    label={Config.lang("Ngay_tang_ca")}
                                    stylingMode={"underlined"}
                                    width={"100%"}
                                    value={dataForm.AttendanceDate}
                                    onValueChanged={e => handleChange("AttendanceDate", e)}
                                />
                            </Col>
                            {rowData.ShiftInOut &&
                                <Col xs={12} sm={12} md={6}>
                                    <TextField
                                        label={Config.isMobile ? Config.lang("So_gio_tang_ca") : " "}
                                        variant={"standard"}
                                        margin={"normal"}
                                        value={`${rowData?.ShiftInOut || 0}` || ""}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            readOnly: true,
                                        }}
                                        className={classes.rmUnderLine}
                                        fullWidth
                                    />
                                </Col>
                            }

                        </Row>
                        <Row>
                            <Col sm={6} md={4}>
                                <FormControl variant={"standard"}
                                             error={!!errorHelperText}
                                             fullWidth={true}>
                                    <Row>
                                        <Col sm={6} xs={6}>
                                            <InputMask
                                                mask={'12:34'}
                                                maskChar={"_"}
                                                alwaysShowMask={true}
                                                formatChars={{
                                                    '1': '[0-9]',
                                                    '2': checkRuleOTFrom ? '[0-3]' : '[0-9]',
                                                    '3': '[0-5]',
                                                    '4': '[0-9]',
                                                }}
                                                onBlur={() => {
                                                    if (dataForm.OTFrom) {
                                                        if (dataForm.OTFrom.split('').includes("_")) {
                                                            setStateErrorText({errorMask: Config.lang('Ban_phai_nhap_dung_dinh_dang_vd_11:11')});
                                                        } else {
                                                            setStateErrorText({errorMask: ""});
                                                        }
                                                    }
                                                    loadApiCheckTime("onBlurOTFrom");
                                                }}
                                                onKeyDown={(e) => {
                                                    if(e?.keyCode === 9) {
                                                        loadApiCheckTime("onTab");
                                                    }
                                                }}
                                                value={dataForm.OTFrom || ""}
                                                onChange={(e) => handleChange("OTFrom", e)}
                                            >
                                                {(props) => {
                                                    return <TextField {...props}
                                                                      label={Config.lang("Gio_tang_ca_tu")}
                                                                      InputLabelProps={{shrink: true}}
                                                                      inputProps={{
                                                                          id: "popupCursorOTFrom",
                                                                          onMouseUp: (e) => {
                                                                              if(window.getSelection()) {
                                                                                  setTypeOTFrom.current = false;
                                                                              }
                                                                          }
                                                                      }}
                                                                      margin={"normal"}
                                                                      variant={"standard"}/>}
                                                }
                                            </InputMask>
                                        </Col>
                                        <Col sm={6} xs={6}>
                                            <InputMask
                                                mask={'12:34'}
                                                maskChar={"_"}
                                                alwaysShowMask={true}
                                                formatChars={{
                                                    '1': '[0-9]',
                                                    '2': checkRuleOTTo ? '[0-3]' : '[0-9]',
                                                    '3': '[0-5]',
                                                    '4': '[0-9]',
                                                }}
                                                onBlur={() => {
                                                    if (dataForm.OTTo) {
                                                        if (dataForm.OTTo.split('').includes("_")) {
                                                            setStateErrorText({errorMask: Config.lang('Ban_phai_nhap_dung_dinh_dang_vd_11:11')});
                                                        } else {
                                                            setStateErrorText({errorMask: ""});
                                                        }
                                                    }
                                                    loadApiCheckTime("onBlurOTTo");
                                                }}
                                                onKeyDown={(e) => {
                                                    if(e?.keyCode === 9) {
                                                        loadApiCheckTime("onTab");
                                                    }
                                                }}
                                                value={dataForm.OTTo  || ""}
                                                onChange={(e) => handleChange("OTTo", e)}
                                            >
                                                {(props) => <TextField {...props}

                                                                       label={Config.lang("Gio_tang_ca_den")}
                                                                       InputLabelProps={{shrink: true}}
                                                                       inputProps={{
                                                                           id: "popupCursorOTTo",
                                                                           onMouseUp: (e) => {
                                                                               if(window.getSelection()) {
                                                                                   setTypeOTTo.current = false;
                                                                               }
                                                                           }
                                                                       }}
                                                                       margin={"normal"}
                                                                       variant={"standard"}/>}
                                            </InputMask>
                                        </Col>
                                    </Row>
                                    <FormHelperText style={{whiteSpace: "nowrap", position: "absolute", textAlign: "center", width: "100%"}}>
                                        {(errorHelperText && errorHelperText["errorMask"]) || (errorHelperText && errorHelperText["errorTime"])}
                                    </FormHelperText>
                                </FormControl>
                            </Col>
                            <Col xs={12} sm={6} md={2}>
                                {(dataForm?.OTHour || dataOtHour?.OTHour) &&
                                <TextField
                                    label={Config.isMobile ? Config.lang("So_gio_tang_ca") : " "}
                                    variant={"standard"}
                                    margin={"normal"}
                                    value={`${dataOtHour?.OTHour || dataForm?.OTHour || 0} h` || ""}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        readOnly: true,
                                    }}
                                    className={classes.rmUnderLine}
                                    fullWidth
                                />
                                }

                            </Col>
                            <Col xs={12} sm={12} md={4}>
                                {paramOtType &&
                                <Combo
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    margin={"normal"}
                                    stylingMode={"underlined"}
                                    label={Config.lang("Loai_tach_tang_ca")}
                                    dataSource={dataCboOtType}
                                    showClearButton={true}
                                    displayExpr={"OTTypeName"}
                                    value={dataForm.OTTypeID || ""}
                                    valueExpr={"OTTypeID"}
                                    onValueChanged={e => handleChange("OTTypeID", e)}
                                    disabled={checkCboOTTypeID}
                                />
                                }

                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <FormGroup row={"true"}>
                                {
                                    dataCaption.map((item,indx) => {
                                        if(!item.Disabled) return null;

                                        return (
                                            <FormControlLabel key={indx}
                                                control={
                                                    <Checkbox
                                                        checked={Boolean(dataCheckboxCaption[item.RefID])}
                                                        onChange={(e) => handleCheckbox(item.RefID,e.target.checked)}
                                                        name={item.RefCaption}
                                                    />
                                                }
                                                label={item.RefCaption}
                                            />
                                        )
                                    })
                                }

                            </FormGroup>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <TextField
                                    label={Config.lang("Ghi_chu")}
                                    variant={"standard"}
                                    margin={"normal"}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={dataForm.Note || ""}
                                    // disabled={loadingForm}
                                    onChange={(e) => handleChange("Note", e)}
                                    fullWidth
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <div>
                        <ButtonGeneral
                            name={Config.lang("Luu")}
                            typeButton={"save"}
                            disabled={ !(iPermission > 1) || isDisabled}
                            style={{textTransform: "uppercase"}}
                            size={"large"}
                            onClick={onSave}
                        />
                    </div>
                </ModalFooter>
            </Modal>

        </>
    );
};

export default compose(
    connect(null,
        (dispatch) => ({
            w29f2056Actions: bindActionCreators(W29F2056Actions, dispatch),
            generalActions: bindActionCreators(generalActions, dispatch),
        })
    ),
)(W29F2056);
